@tailwind base;
@tailwind components;
@tailwind utilities;

.hero-jp {
    pointer-events: none;
  }

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-track {}

  ::-webkit-scrollbar-thumb {
    background: rgba(159, 123, 107, 1);
  }

  html {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }


.main-h2-customStyle {
    -webkit-transform:translate3d(0, 100px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(3deg) skew(0, 0);
    -moz-transform:translate3d(0, 100px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(3deg) skew(0, 0);
    -ms-transform:translate3d(0, 100px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(3deg) skew(0, 0);
    transform:translate3d(0, 100px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(3deg) skew(0, 0);
    opacity:0;
    margin-bottom: 1rem;
}

.primary-button {
  z-index: 2;
}


.t--white {
  display: block;
}

.banner__title {
  margin-bottom: 2rem;
}
.landing__banner .banner__title {
  font-size: 4.5rem;
  line-height: 5.375rem;
}

.landing__banner .banner__title span {
  display: block;
}

.landing__banner .banner__title span {
  display: block;
}

.t--gradient-purple {
  background: linear-gradient(128.38deg,#BEBDFF 0%,#B378FF 20%,#4340FC 50%,#A167FF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

@media (min-width: 960px) {
.banner__title {
    margin-bottom: 2rem;
}
}

@media (min-width: 1280px) {
.banner__title {
    font-size: 4.5rem;
    line-height: 5.375rem;
}
}
.landing__banner .banner__title {
  margin: 0 0 1rem;
  line-height: 1.1;
  font-weight: 700;
  padding-right: 24px;
  font-family: filson-soft,sans-serif;
  text-transform: uppercase;
}



.landing__text {
    max-width: 655px;
}

@media (min-width: 1280px) {
.landing__text {
    font-size: 2rem;
    line-height: 2.4375rem;
}
}

.landing__banner .banner__buttons {
  margin-top: 1rem;
  display: flex;
  align-items: center;
}

.logo-link {
  filter: invert(1);
}

.inverted-logo {
  filter: invert(1);

}


#base-full-page-loader2 {
  animation: hideAnimation 0s ease-in 5s;
  animation-fill-mode: forwards;
}

@keyframes hideAnimation {
  to {
    visibility: hidden;
    width: 0;
    height: 0;
  }
}


.landing__text {
  font-weight: 200;
  max-width: 600px;
  font-size: 1.25rem;
  line-height: 1.625rem;
}
.banner__buttons {
  margin-top: 1rem;
  display: flex;
  align-items: center;
}
.d-flex {
  display: flex!important;
}
.sidebar__footer .footer__image {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
}
.flex-grow-1 {
  flex-grow: 1!important;
}
.sidebar__footer .footer__button {
  width: 36px;
  min-width: 36px;
  padding: 0;
  color: #757b8c;
}
.v-btn--icon.v-btn--density-default {
  width: calc(var(--v-btn-height) + 12px);
  height: calc(var(--v-btn-height) + 12px);
}
.v-btn--icon.v-btn--size-default {
  font-size: 1rem;
}

.mdi:before, .mdi-set {
  display: inline-block;
  font: 24px/1 Material Design Icons;
  font-size: inherit;
  text-rendering: auto;
  line-height: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.v-btn.v-btn--density-default {
  height: calc(var(--v-btn-height) + 0px);
}
.flex-grow-0 {
  flex-grow: 0!important;
}
.flex-grow-0 {
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  -webkit-flex-grow: 0;
  flex-grow: 0;
}

.navigation__link {
  display: flex;
  background: transparent;
  justify-content: flex-start;
  align-content: center;
  padding: 0.75rem 1rem;
  border-radius: 50px;
  color: #757b8c;
  position: relative;
  overflow: hidden;
  transition: color .3s cubic-bezier(0,0,0,1),background-color .3s cubic-bezier(0,0,0,1);
}

.staking-page-active {
  background: #2c285d !important;
  color: white !important;
}

@media (max-width: 728px) {
.carousel__slide {
width: 75% !important;
}
.v-slide-group__content {
  width: 90vw;
  display: block !important;
}
.announcement-banner {
  width: fit-content !important;
  margin-bottom: 20px;
}
.carousel__track {
  overflow: scroll;
}
}

@media (min-width: 1280px) {
.banner__buttons .v-btn {
  font-size: 1rem;
  height: 48px;
  padding: 0 30px;
}
}
.banner__buttons .v-btn {
  margin-top: 1rem;
}

.landing__banner .banner__buttons .v-btn:first-child {
  margin-right: 1rem;
}

@media (min-width: 1280px) {
.landing__banner .banner__buttons .v-btn {
  font-size: 1rem;
  height: 48px;
  padding: 0 30px;
}
}

.landing__banner .banner__buttons .v-btn {
  margin-top: 1rem;
}
.v-btn.btn-purple-gradient {
  justify-content: center;
  border-width: 1px;
  border-style: solid;
  box-shadow: none;
  border-radius: 30px;
  color: #fff;
  background: linear-gradient(270deg, #4340fc, #36379b);  
  border-color: transparent;
}
.v-btn.v-btn--density-default {
  height: calc(var(--v-btn-height) + 0px);
}

.margin-btn {
  margin-left: auto;
  margin-right: auto;
  background-color: #9e9cff;
  color: white;
}

@media (min-width: 1280px) {
.banner__buttons .v-btn {
    font-size: 1rem;
    height: 48px;
    padding: 0 30px;
}
}
.banner__buttons .v-btn {
    margin-top: 1rem;
}
.v-btn.btn-purple-outline, .v-btn.btn-purple-outline .v-btn__overlay, .v-btn.btn-purple-outline .v-btn__underlay {
    border-radius: 30px;
}
.v-btn.btn-purple-outline {
    color: #9e9cff;
    border-radius: 30px;
    overflow: hidden;
    background: transparent;
    transition: all .3s ease-in-out;
}

.v-btn.btn-purple-outline .v-btn__overlay {
  background: linear-gradient(270deg,#4340fc,#36379b);
  opacity: 0;
  z-index: 2;
  background-color: transparent;
  border-radius: 30px;
  transition: opacity .3s ease-in-out;
}
.v-btn.btn-purple-outline .v-btn__underlay {
  opacity: 1;
  z-index: 1;
  border: 1px solid #9E9CFF;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  margin: 1px;
}

.v-btn.btn-purple-outline .v-btn__content {
  z-index: 3;
}

.v-btn__content {
  grid-area: content;
  justify-content: center;
  white-space: nowrap;
}
.v-btn__append {
  align-items: center;
  display: flex;
  transition: transform,opacity .2s cubic-bezier(.4,0,.2,1);
}

.v-btn:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  border: 2px solid currentColor;
  border-radius: inherit;
  opacity: 0;
  transition: opacity .2s ease-in-out;
}

@media (min-width: 960px) {
  .landing__banner .banner__content {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}
}

.v-application.is-landing-page section {
  position: relative;
}

.v-application.is-landing-page {
  background: linear-gradient(176.68deg, #272746 15%, #3f1e83 85%);
}

@media (min-width: 960px) {
.landing__banner {
  padding-top: 130px;
}
}
.landing__banner {
  top: 0;
  left: 0;
  width: 100%;
  position: relative;
}

@media (min-width: 1280px) {
.landing__banner .banner__image {
    max-width: 500px;
}
}
@media (min-width: 960px) {
.landing__banner .banner__image {
    margin-bottom: 0;
}
}
.landing__banner .banner__image {
    position: relative;
    margin-bottom: 2rem;
    width: 100%;
    z-index: 1;
    max-width: 350px;
}

.landing__banner .banner__image .image {
  aspect-ratio: 1;
  width: 100%;
  overflow: hidden;
  border-radius: 20px;
  background-color: beige;
}
.landing__banner .banner__image:after {
  content: "";
  display: block;
  width: 40%;
  aspect-ratio: 1;
  border-radius: 50%;
  background: rgb(255,244,245);
  filter: blur(80px);
  transform: rotate(-2.2deg);
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  animation: glow-moving 3s linear alternate infinite;
  transition: opacity 1s ease-in-out;
}

.landing__banner .banner__image .image {
  aspect-ratio: 1;
  width: 100%;
  overflow: hidden;
  border-radius: 20px;
}



.wrapcolor {
  background: #141518;
  z-index: 1000;
}

.is-open-to-public:before {
  content: "";
  display: block;
  opacity: .5;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-40%);
  background: url(/public/images/glow-2.bb3da2bd.png);
  background-size: contain;
  width: 180vh;
  height: 180vh;
}

.ball {
  position: relative;
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation: floating-item 5s infinite ease-in-out !important;
}


.landing__ball--1 {
  margin-top: -170px;
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.landing__ball {
  position: absolute;
  right: 8vw;
  top: 90vh;
  width: 400px;
}

.landing__ball--2 {
  top: 4vh;
  left: 5vh;
  margin-top: -17.8px;
}

.nft-soon:after {
    content: "TBA";
    position: absolute;
    font-size: 9px;
    font-weight: 400;
    top: -11px;
    border-radius: 3px;
    right: 16px;
    padding-left: 6px;
    background-color: #e42575;
    color: #f5f5f5;
    padding-right: 6px;
    opacity: 1 !important;
}



.nft-soon {
  text-decoration: line-through;
  opacity: .5;
}


.header-sidebar__nav .nav__link span {
  display: inline-block;
  transition: transform .3s cubic-bezier(0, 0, 0, 1), opacity .3s ease-in-out;
  line-height: 1;
  padding: .75rem 1.5rem;
  position: relative;
  border-radius: 100px
}

.header-sidebar__nav .nav__link {
  font-size: 1.5rem
}


.header-sidebar__nav .nav__link .coming-soon {
  color: #000;
  background: #ffffff;
  position: absolute;
  left: 0;
  transform: translateY(100%);
  opacity: 0;
  user-select: none;
  transition: transform .3s cubic-bezier(0, 0, 0, 1), opacity .3s ease-in-out;
  pointer-events: none;
  border-radius: 100px
}

.header-sidebar__nav .nav__link.is-active {
  pointer-events: none
}

.header-sidebar__nav .nav__link:not(.nav__link--coming-soon):before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #FFDD00;
  transform: translateY(110%);
  transition: transform .3s cubic-bezier(0, 0, 0, 1);
  border-radius: 100px
}

.header-sidebar__nav .nav__link:not(.nav__link--coming-soon):hover,
.header-sidebar__nav .nav__link:not(.nav__link--coming-soon).is-active {
  color: #000
}

.header-sidebar__nav .nav__link:not(.nav__link--coming-soon):hover:before,
.header-sidebar__nav .nav__link:not(.nav__link--coming-soon).is-active:before {
  transform: translateY(0)
}

.header-sidebar__nav .nav__link--coming-soon {
  cursor: auto;
  display: block;
  margin-bottom: .25rem
}

.header-sidebar__nav .nav__link--coming-soon span {
  opacity: .2
}

.header-sidebar__nav .nav__link--coming-soon:hover span {
  opacity: 0
}

.header-sidebar__nav .nav__link--coming-soon:hover .coming-soon {
  opacity: 1;
  transform: translateY(0)
}
.v-btn__overlay, .v-btn__underlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.v-btn.btn-purple-outline:hover .v-btn__overlay {
  opacity: 1;
}
.v-btn.btn-purple-outline .v-btn__overlay {
  background: linear-gradient(270deg,#4340fc,#36379b);
  opacity: 0;
  z-index: 2;
  background-color: transparent;
  border-radius: 30px;
  transition: opacity .3s ease-in-out;
}
.v-btn.btn-purple-outline .v-btn__underlay {
  opacity: 1;
  z-index: 1;
  border: 1px solid #9E9CFF;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  margin: 1px;
}



.v-btn.btn-purple-outline, .v-btn.btn-purple-outline .v-btn__overlay, .v-btn.btn-purple-outline .v-btn__underlay {
  border-radius: 30px;
}
.v-btn__overlay {
  background-color: currentColor;
  border-radius: inherit;
  opacity: 0;
  transition: opacity .2s ease-in-out;
}
.header-sidebar__nav .nav__link--coming-soon:before {
  display: none
}

.header-sidebar {
  position: fixed;
  right: 0;
  top: 0;
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  max-width: 600px;
  background: #8459ff;
  padding: 30px;
  user-select: none;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  transition: transform .5s cubic-bezier(0, 0, 0, 1);
  z-index: 8;
  pointer-events: auto
}

@media (max-width: 599.98px) {
  .header-sidebar {
    padding: 20px 15px
  }
}

.header-sidebar.slide-left-enter,
.header-sidebar.slide-left-leave-to {
  transform: translate(100%)
}

.header-sidebar__header {
  text-align: right
}

.header-sidebar__header .btn-close {
  border-radius: 50%;
  width: 40px;
  height: 40px
}

@media (min-width: 1920px) {
  .header-sidebar__header .btn-close {
    width: 56px;
    height: 56px
  }
}

.header-sidebar__nav .nav__list {
  list-style: none;
  padding: 0;
  margin: 0
}

.header-sidebar__nav .nav__item {
  display: block;
  position: relative;
  overflow: hidden;
  --ani-d: .1s;
  transition: transform .5s cubic-bezier(0, 0, 0, 1), opacity .3s ease-in
}

.header.show-menu .header-sidebar__nav .nav__item {
  transition-delay: calc(var(--ani-d) * 1)
}

.header.show-menu .header-sidebar__nav .nav__item:nth-child(2) {
  transition-delay: calc(var(--ani-d) * 2)
}

.header.show-menu .header-sidebar__nav .nav__item:nth-child(3) {
  transition-delay: calc(var(--ani-d) * 3)
}

.header.show-menu .header-sidebar__nav .nav__item:nth-child(4) {
  transition-delay: calc(var(--ani-d) * 4)
}

.header.show-menu .header-sidebar__nav .nav__item:nth-child(5) {
  transition-delay: calc(var(--ani-d) * 5)
}

.header.show-menu .header-sidebar__nav .nav__item:nth-child(6) {
  transition-delay: calc(var(--ani-d) * 6)
}

.header.show-menu .header-sidebar__nav .nav__item:nth-child(7) {
  transition-delay: calc(var(--ani-d) * 7)
}

.header.show-menu .header-sidebar__nav .nav__item:nth-child(8) {
  transition-delay: calc(var(--ani-d) * 8)
}

.header.show-menu .header-sidebar__nav .nav__item:nth-child(9) {
  transition-delay: calc(var(--ani-d) * 9)
}

.header:not(.show-menu) .header-sidebar__nav .nav__item {
  transform: translate(100%);
  opacity: 0;
  transition-delay: .5s
}

.social__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  padding: 0 25px
}

@media (max-width: 959.98px) {
  .social__footer {
    padding: 0 15px
  }
}

ul.header-sidebar__social {
  list-style: none;
  padding: 0;
  margin-top: auto
}

@media (min-width: 600px) and (max-width: 959.98px) {
  ul.header-sidebar__social {
    margin-left: auto
  }
}

ul.header-sidebar__social .social__item {
  display: inline-block;
  position: relative;
  overflow: hidden;
  margin-right: 1.5rem
}

@media (max-width: 599.98px) {
  ul.header-sidebar__social .social__item {
    margin-right: 1rem
  }
}

ul.header-sidebar__social .social__link {
  text-decoration: none;
  color: #fff
}

ul.header-sidebar__social .social__link .v-icon {
  color: #fff;
  font-size: 1.5rem;
  width: auto
}

@media (min-width: 600px) and (max-width: 959.98px) {
  ul.header-sidebar__social .social__link .v-icon {
    font-size: 1.25rem
  }
}

ul.header-sidebar__social span.social__link {
  opacity: .3
}

.v-btn {
  align-items: center;
  border-radius: 4px;
  display: inline-grid;
  grid-template-areas: "prepend content append";
  grid-template-columns: max-content auto max-content;
  font-weight: 500;
  justify-content: center;
  letter-spacing: .0892857143em;
  line-height: normal;
  max-width: 100%;
  outline: none;
  position: relative;
  text-decoration: none;
  text-indent: .0892857143em;
  text-transform: uppercase;
  transition-property: box-shadow, transform, opacity, background;
  transition-duration: .28s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  user-select: none;
  vertical-align: middle;
  flex-shrink: 0;
  border-color: rgba(var(--v-border-color), var(--v-border-opacity));
  border-style: solid;
  border-width: 0
}

.v-btn--size-x-small {
  --v-btn-size: .625rem;
  --v-btn-height: 20px;
  font-size: .625rem;
  min-width: 36px;
  padding: 0 8px
}

.v-btn--size-small {
  --v-btn-size: .75rem;
  --v-btn-height: 28px;
  font-size: .75rem;
  min-width: 50px;
  padding: 0 12px
}

.v-btn--size-default {
  --v-btn-size: .875rem;
  --v-btn-height: 36px;
  font-size: .875rem;
  min-width: 64px;
  padding: 0 16px
}

.v-btn--size-large {
  --v-btn-size: 1rem;
  --v-btn-height: 44px;
  font-size: 1rem;
  min-width: 78px;
  padding: 0 20px
}

.v-btn--size-x-large {
  --v-btn-size: 1.125rem;
  --v-btn-height: 52px;
  font-size: 1.125rem;
  min-width: 92px;
  padding: 0 24px
}

.v-btn.v-btn--density-default {
  height: calc(var(--v-btn-height) + 0px)
}

.v-btn.v-btn--density-comfortable {
  height: calc(var(--v-btn-height) + -8px)
}

.v-btn.v-btn--density-compact {
  height: calc(var(--v-btn-height) + -12px)
}

.v-btn--border {
  border-width: thin;
  box-shadow: none
}

.v-btn--absolute {
  position: absolute
}

.v-btn--fixed {
  position: fixed
}

.v-btn:hover>.v-btn__overlay {
  opacity: calc(.04 * var(--v-theme-overlay-multiplier))
}

.v-btn:focus-visible>.v-btn__overlay {
  opacity: calc(.12 * var(--v-theme-overlay-multiplier))
}

@supports not selector(:focus-visible) {
  .v-btn:focus>.v-btn__overlay {
    opacity: calc(.12 * var(--v-theme-overlay-multiplier))
  }
}

.v-btn--active>.v-btn__overlay,
.v-btn[aria-haspopup=menu][aria-expanded=true]>.v-btn__overlay {
  opacity: calc(.12 * var(--v-theme-overlay-multiplier))
}

.v-btn--active:hover>.v-btn__overlay,
.v-btn[aria-haspopup=menu][aria-expanded=true]:hover>.v-btn__overlay {
  opacity: calc(.12 * var(--v-theme-overlay-multiplier))
}

.v-btn--active:focus>.v-btn__overlay,
.v-btn[aria-haspopup=menu][aria-expanded=true]:focus>.v-btn__overlay {
  opacity: calc(.16 * var(--v-theme-overlay-multiplier))
}

.v-btn--variant-plain,
.v-btn--variant-outlined,
.v-btn--variant-text,
.v-btn--variant-tonal {
  background: transparent;
  color: inherit
}

.v-btn--variant-plain {
  opacity: .62
}

.v-btn--variant-plain:focus,
.v-btn--variant-plain:hover {
  opacity: 1
}

.v-btn--variant-plain .v-btn__overlay {
  display: none
}

.v-btn--variant-elevated,
.v-btn--variant-flat {
  background: rgb(var(--v-theme-surface));
  color: rgba(var(--v-theme-on-surface), var(--v-high-emphasis-opacity))
}

.v-btn--variant-elevated {
  box-shadow: 0 3px 1px -2px #0003, 0 2px 2px #00000024, 0 1px 5px #0000001f
}

.v-btn--variant-flat {
  box-shadow: 0 0 #0003, 0 0 #00000024, 0 0 #0000001f
}

.v-btn--variant-outlined {
  border: thin solid currentColor
}

.v-btn--variant-text .v-btn__overlay {
  background: currentColor
}

.v-btn--variant-tonal .v-btn__underlay {
  background: currentColor;
  opacity: var(--v-activated-opacity);
  border-radius: inherit;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  transition: opacity .2s ease-in-out
}

@supports selector(:focus-visible) {
  .v-btn:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    border: 2px solid currentColor;
    border-radius: inherit;
    opacity: 0;
    transition: opacity .2s ease-in-out
  }

  .v-btn:focus-visible:after {
    opacity: calc(.25 * var(--v-theme-overlay-multiplier))
  }
}

.v-btn--icon {
  border-radius: 50%;
  min-width: 0;
  padding: 0
}

.v-btn--icon.v-btn--size-default {
  font-size: 1rem
}

.v-btn--icon.v-btn--density-default {
  width: calc(var(--v-btn-height) + 12px);
  height: calc(var(--v-btn-height) + 12px)
}

.v-btn--icon.v-btn--density-comfortable {
  width: calc(var(--v-btn-height) + 0px);
  height: calc(var(--v-btn-height) + 0px)
}

.v-btn--icon.v-btn--density-compact {
  width: calc(var(--v-btn-height) + -8px);
  height: calc(var(--v-btn-height) + -8px)
}

.v-btn--elevated:hover,
.v-btn--elevated:focus {
  box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f
}

.v-btn--elevated:active {
  box-shadow: 0 5px 5px -3px #0003, 0 8px 10px 1px #00000024, 0 3px 14px 2px #0000001f
}

.v-btn--flat {
  box-shadow: none
}

.v-btn--block {
  display: flex;
  flex: 1 0 auto;
  min-width: 100%
}

.v-btn--disabled {
  pointer-events: none
}

.v-btn--disabled.v-btn--disabled {
  color: rgba(var(--v-theme-on-surface), .26);
  opacity: .4615384615
}

.v-btn--disabled.v-btn--variant-elevated,
.v-btn--disabled.v-btn--variant-flat {
  background: rgb(var(--v-theme-surface));
  box-shadow: none;
  opacity: 1
}

.v-btn--disabled.v-btn--variant-elevated .v-btn__overlay,
.v-btn--disabled.v-btn--variant-flat .v-btn__overlay {
  opacity: .4615384615
}

.v-btn--loading {
  pointer-events: none
}

.v-btn--loading .v-btn__content,
.v-btn--loading .v-btn__prepend,
.v-btn--loading .v-btn__append {
  opacity: 0
}

.v-btn--stacked .v-btn__content {
  flex-direction: column;
  line-height: 1.25
}

.v-btn--stacked.v-btn--size-x-small {
  --v-btn-size: .625rem;
  --v-btn-height: 56px;
  font-size: .625rem;
  min-width: 56px;
  padding: 0 12px
}

.v-btn--stacked.v-btn--size-small {
  --v-btn-size: .75rem;
  --v-btn-height: 64px;
  font-size: .75rem;
  min-width: 64px;
  padding: 0 14px
}

.v-btn--stacked.v-btn--size-default {
  --v-btn-size: .875rem;
  --v-btn-height: 72px;
  font-size: .875rem;
  min-width: 72px;
  padding: 0 16px
}

.v-btn--stacked.v-btn--size-large {
  --v-btn-size: 1rem;
  --v-btn-height: 80px;
  font-size: 1rem;
  min-width: 80px;
  padding: 0 18px
}

.v-btn--stacked.v-btn--size-x-large {
  --v-btn-size: 1.125rem;
  --v-btn-height: 88px;
  font-size: 1.125rem;
  min-width: 88px;
  padding: 0 20px
}

.v-btn--stacked.v-btn--density-default {
  height: calc(var(--v-btn-height) + 0px)
}

.v-btn--stacked.v-btn--density-comfortable {
  height: calc(var(--v-btn-height) + -16px)
}

.v-btn--stacked.v-btn--density-compact {
  height: calc(var(--v-btn-height) + -24px)
}

.v-btn--rounded {
  border-radius: 24px
}

.v-btn .v-icon {
  --v-icon-size-multiplier: .8571428571
}

.v-btn--icon .v-icon {
  --v-icon-size-multiplier: 1
}

.v-btn--stacked .v-icon {
  --v-icon-size-multiplier: 1.1428571429
}

.v-btn__loader {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%
}

.v-btn__content,
.v-btn__prepend,
.v-btn__append {
  align-items: center;
  display: flex;
  transition: transform, opacity .2s cubic-bezier(.4, 0, .2, 1)
}

.v-btn__prepend {
  grid-area: prepend;
  margin-inline-start: calc(var(--v-btn-height) / -9);
  margin-inline-end: calc(var(--v-btn-height) / 4.5)
}

.v-btn__append {
  grid-area: append;
  margin-inline-start: calc(var(--v-btn-height) / 4.5);
  margin-inline-end: calc(var(--v-btn-height) / -9)
}

.v-btn__content {
  grid-area: content;
  justify-content: center;
  white-space: nowrap
}

.v-btn__content>.v-icon--start {
  margin-inline-start: calc(var(--v-btn-height) / -9);
  margin-inline-end: calc(var(--v-btn-height) / 4.5)
}

.v-btn__content>.v-icon--end {
  margin-inline-start: calc(var(--v-btn-height) / 4.5);
  margin-inline-end: calc(var(--v-btn-height) / -9)
}

.v-btn--stacked .v-btn__content {
  white-space: normal
}

.v-btn__overlay {
  background-color: currentColor;
  border-radius: inherit;
  opacity: 0;
  transition: opacity .2s ease-in-out
}

.v-btn__overlay,
.v-btn__underlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none
}

.v-app-bar .v-btn {
  box-shadow: 0 0 #0003, 0 0 #00000024, 0 0 #0000001f
}

.v-card-actions .v-btn {
  padding: 0 8px;
  box-shadow: 0 0 #0003, 0 0 #00000024, 0 0 #0000001f
}

.v-card-actions .v-btn~.v-btn {
  margin-inline-start: .5rem
}

.v-banner-actions .v-btn {
  padding: 0 8px
}

.v-pagination .v-btn {
  border-radius: 4px
}

.v-pagination__item--is-active .v-btn__overlay {
  opacity: var(--v-border-opacity)
}

.v-snackbar-actions .v-btn {
  padding: 0 8px
}

.v-btn-toggle .v-btn.v-btn--selected:not(.v-btn--disabled) .v-btn__overlay {
  opacity: var(--v-activated-opacity)
}

.v-btn-group {
  display: inline-flex;
  flex-wrap: nowrap;
  max-width: 100%;
  min-width: 0;
  overflow: hidden;
  vertical-align: middle;
  border-color: rgba(var(--v-border-color), var(--v-border-opacity));
  border-style: solid;
  border-width: 0;
  box-shadow: 0 0 #0003, 0 0 #00000024, 0 0 #0000001f;
  border-radius: 4px;
  background: transparent;
  color: rgba(var(--v-theme-on-surface), var(--v-high-emphasis-opacity))
}

.v-btn-group--border {
  border-width: thin;
  box-shadow: none
}

.v-btn-group--density-default.v-btn-group {
  height: 48px
}

.v-btn-group--density-comfortable.v-btn-group {
  height: 40px
}

.v-btn-group--density-compact.v-btn-group {
  height: 36px
}

.v-btn-group .v-btn {
  border-radius: 0;
  border-color: inherit
}

.v-btn-group .v-btn:not(:last-child) {
  border-inline-end: none
}

.v-btn-group .v-btn:not(:first-child) {
  border-inline-start: none
}

.v-btn-group .v-btn:first-child {
  border-start-start-radius: inherit;
  border-end-start-radius: inherit
}

.v-btn-group .v-btn:last-child {
  border-start-end-radius: inherit;
  border-end-end-radius: inherit
}

.v-btn-group--divided .v-btn:not(:last-child) {
  border-inline-end-width: thin;
  border-inline-end-style: solid;
  border-inline-end-color: rgba(var(--v-border-color), var(--v-border-opacity))
}

.v-btn-group--tile {
  border-radius: 0
}

.v-icon {
  --v-icon-size-multiplier: 1;
  align-items: center;
  display: inline-flex;
  font-feature-settings: "liga";
  height: 1em;
  justify-content: center;
  letter-spacing: normal;
  line-height: 1;
  position: relative;
  text-indent: 0;
  user-select: none;
  vertical-align: middle;
  width: 1em
}

.v-icon--clickable {
  cursor: pointer
}

.v-icon--size-x-small {
  font-size: calc(var(--v-icon-size-multiplier) * 1em)
}

.v-icon--size-small {
  font-size: calc(var(--v-icon-size-multiplier) * 1.25em)
}

.v-icon--size-default {
  font-size: calc(var(--v-icon-size-multiplier) * 1.5em)
}

.v-icon--size-large {
  font-size: calc(var(--v-icon-size-multiplier) * 1.75em)
}

.v-icon--size-x-large {
  font-size: calc(var(--v-icon-size-multiplier) * 2em)
}

.v-icon__svg {
  fill: currentColor;
  width: 100%;
  height: 100%
}

.v-icon--start {
  margin-inline-end: 8px
}

.v-btn.v-btn--stacked .v-icon--start {
  margin-inline-start: 0;
  margin-inline-end: 0;
  margin-bottom: 4px
}

.v-icon--end {
  margin-inline-start: 8px
}

.v-btn.v-btn--stacked .v-icon--end {
  margin-inline-start: 0;
  margin-inline-end: 0;
  margin-top: 4px
}

.v-progress-circular {
  align-items: center;
  display: inline-flex;
  justify-content: center;
  position: relative;
  vertical-align: middle
}

.v-progress-circular>svg {
  width: 100%;
  height: 100%;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0
}

.v-progress-circular__content {
  align-items: center;
  display: flex;
  justify-content: center
}

.v-progress-circular__underlay {
  color: rgba(var(--v-border-color), var(--v-border-opacity));
  stroke: currentColor;
  z-index: 1
}

.v-progress-circular__overlay {
  stroke: currentColor;
  transition: all .2s ease-in-out, stroke-width 0s;
  z-index: 2
}

.v-progress-circular--size-x-small {
  height: 16px;
  width: 16px
}

.v-progress-circular--size-small {
  height: 24px;
  width: 24px
}

.v-progress-circular--size-default {
  height: 32px;
  width: 32px
}

.v-progress-circular--size-large {
  height: 48px;
  width: 48px
}

.v-progress-circular--size-x-large {
  height: 64px;
  width: 64px
}

.v-progress-circular--indeterminate>svg {
  animation: progress-circular-rotate 1.4s linear infinite;
  transform-origin: center center;
  transition: all .2s ease-in-out
}

.v-progress-circular--indeterminate .v-progress-circular__overlay {
  animation: progress-circular-dash 1.4s ease-in-out infinite;
  stroke-dasharray: 25, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round
}

.v-progress-circular--disable-shrink>svg {
  animation-duration: .7s
}

.v-progress-circular--disable-shrink .v-progress-circular__overlay {
  animation: none
}

.v-progress-circular--indeterminate:not(.v-progress-circular--visible)>svg,
.v-progress-circular--indeterminate:not(.v-progress-circular--visible) .v-progress-circular__overlay {
  animation-play-state: paused !important
}

@keyframes progress-circular-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0px
  }

  50% {
    stroke-dasharray: 100, 200;
    stroke-dashoffset: -15px
  }

  to {
    stroke-dasharray: 100, 200;
    stroke-dashoffset: -124px
  }
}

@keyframes progress-circular-rotate {
  to {
    transform: rotate(270deg)
  }
}

.v-ripple__container {
  color: inherit;
  border-radius: inherit;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
  z-index: 0;
  pointer-events: none;
  contain: strict
}

.v-ripple__animation {
  color: inherit;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  background: currentColor;
  opacity: 0;
  pointer-events: none;
  overflow: hidden;
  will-change: transform, opacity
}

.v-ripple__animation--enter {
  transition: none
}

.v-ripple__animation--in {
  transition: transform .25s cubic-bezier(0, 0, .2, 1), opacity .1s cubic-bezier(0, 0, .2, 1)
}

.v-ripple__animation--out {
  transition: opacity .3s cubic-bezier(0, 0, .2, 1)
}

.v-progress-linear {
  background: transparent;
  overflow: hidden;
  position: relative;
  transition: .2s cubic-bezier(.4, 0, .2, 1);
  width: 100%
}

.v-progress-linear__background {
  background: currentColor;
  bottom: 0;
  left: 0;
  opacity: var(--v-border-opacity);
  position: absolute;
  top: 0;
  transition-property: width, left, right;
  transition: inherit
}

.v-progress-linear__content {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%
}

.v-progress-linear__determinate,
.v-progress-linear__indeterminate {
  background: currentColor
}

.v-progress-linear__determinate {
  height: inherit;
  left: 0;
  position: absolute;
  transition: inherit;
  transition-property: width, left, right
}

.v-progress-linear__indeterminate .long,
.v-progress-linear__indeterminate .short {
  animation-play-state: paused;
  animation-duration: 2.2s;
  animation-iteration-count: infinite;
  bottom: 0;
  height: inherit;
  left: 0;
  position: absolute;
  right: auto;
  top: 0;
  width: auto;
  will-change: left, right
}

.v-progress-linear__indeterminate .long {
  animation-name: indeterminate-ltr
}

.v-progress-linear__indeterminate .short {
  animation-name: indeterminate-short-ltr
}

.v-progress-linear__stream {
  animation: stream .25s infinite linear;
  animation-play-state: paused;
  bottom: 0;
  left: auto;
  opacity: .3;
  pointer-events: none;
  position: absolute;
  transition: inherit;
  transition-property: width, left, right
}

.v-progress-linear--reverse .v-progress-linear__background,
.v-progress-linear--reverse .v-progress-linear__determinate,
.v-progress-linear--reverse .v-progress-linear__content,
.v-progress-linear--reverse .v-progress-linear__indeterminate .long,
.v-progress-linear--reverse .v-progress-linear__indeterminate .short {
  left: auto;
  right: 0
}

.v-progress-linear--reverse .v-progress-linear__indeterminate .long {
  animation-name: indeterminate-rtl
}

.v-progress-linear--reverse .v-progress-linear__indeterminate .short {
  animation-name: indeterminate-short-rtl
}

.v-progress-linear--reverse .v-progress-linear__stream {
  right: auto
}

.v-progress-linear--absolute,
.v-progress-linear--fixed {
  left: 0;
  z-index: 1
}

.v-progress-linear--absolute {
  position: absolute
}

.v-progress-linear--fixed {
  position: fixed
}

.v-progress-linear--rounded {
  border-radius: 4px
}

.v-progress-linear--rounded.v-progress-linear--rounded-bar .v-progress-linear__determinate,
.v-progress-linear--rounded.v-progress-linear--rounded-bar .v-progress-linear__indeterminate {
  border-radius: inherit
}

.v-progress-linear--striped .v-progress-linear__determinate {
  animation: progress-linear-stripes 1s infinite linear;
  background-image: linear-gradient(135deg, hsla(0deg, 0%, 100%, .25) 25%, transparent 0, transparent 50%, hsla(0deg, 0%, 100%, .25) 0, hsla(0deg, 0%, 100%, .25) 75%, transparent 0, transparent);
  background-repeat: repeat;
  background-size: var(--v-progress-linear-height)
}

.v-progress-linear--active .v-progress-linear__indeterminate .long,
.v-progress-linear--active .v-progress-linear__indeterminate .short,
.v-progress-linear--active .v-progress-linear__stream {
  animation-play-state: running
}

.v-progress-linear--rounded-bar .v-progress-linear__determinate,
.v-progress-linear--rounded-bar .v-progress-linear__indeterminate,
.v-progress-linear--rounded-bar .v-progress-linear__stream+.v-progress-linear__background {
  border-radius: 4px
}

.v-progress-linear--rounded-bar .v-progress-linear__determinate.v-locale--is-ltr,
.v-locale--is-ltr .v-progress-linear--rounded-bar .v-progress-linear__determinate {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0
}

.v-progress-linear--rounded-bar .v-progress-linear__determinate.v-locale--is-rtl,
.v-locale--is-rtl .v-progress-linear--rounded-bar .v-progress-linear__determinate {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0
}

@keyframes indeterminate-ltr {
  0% {
    left: -90%;
    right: 100%
  }

  60% {
    left: -90%;
    right: 100%
  }

  to {
    left: 100%;
    right: -35%
  }
}

@keyframes indeterminate-rtl {
  0% {
    left: 100%;
    right: -90%
  }

  60% {
    left: 100%;
    right: -90%
  }

  to {
    left: -35%;
    right: 100%
  }
}

@keyframes indeterminate-short-ltr {
  0% {
    left: -200%;
    right: 100%
  }

  60% {
    left: 107%;
    right: -8%
  }

  to {
    left: 107%;
    right: -8%
  }
}

@keyframes indeterminate-short-rtl {
  0% {
    left: 100%;
    right: -200%
  }

  60% {
    left: -8%;
    right: 107%
  }

  to {
    left: -8%;
    right: 107%
  }
}

@keyframes stream {
  to {
    transform: translate(var(--v-progress-linear-stream-to))
  }
}

@keyframes progress-linear-stripes {
  0% {
    background-position-x: var(--v-progress-linear-height)
  }
}

.header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 99;
  padding: 10px 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, .12) 0%, rgba(0, 0, 0, 0) 100%);
  transition: transform 1s .75s cubic-bezier(0, 0, 0, 1);
  pointer-events: none
}

.header:before {
  content: "";
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background: #000000;
  transition: transform .5s cubic-bezier(0, 0, 0, 1)
}

.header:not(.is-scrolled):before {
  transform: translateY(-100%)
}

@media (min-width: 960px) {
  .v-application.has-sidebar .header {
    width: calc(100% - var(--sidebar-width) - var(--sidebar-margin));
    margin-left: calc(var(--sidebar-width) + var(--sidebar-margin))
  }

  .v-application.has-sidebar .header:before {
    display: none
  }
}

@media (min-width: 1280px) {
  .v-application.has-sidebar .header.is-scrolled .v-container {
    padding-top: 5px
  }
}

.header .v-btn {
  pointer-events: auto
}

.header:focus,
.header:focus-visible {
  outline: 0;
  box-shadow: none
}

.header.is-out {
  transform: translateY(-100%)
}

.header .v-container {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  position: relative;
  z-index: 6;
  transition: padding .5s cubic-bezier(0, 0, 0, 1)
}

@media (min-width: 960px) {
  .header .v-container {
    max-width: none;
    width: 90%
  }
}

.header__logo {
  display: block;
  width: 110px;
  pointer-events: auto
}

@media (max-width: 959.98px) {
  .header__logo {
    width: 68px
  }
}

@media (min-width: 1280px) {
  .header__logo {
    width: 140px
  }
}

.header__logo svg {
  width: 100%;
  height: auto;
  display: block
}

.header__logo svg path,
.header__logo svg ellipse {
  fill: #fff;
  transition: fill 1s cubic-bezier(0, 0, 0, 1)
}

.header__logo svg circle {
  stroke: #fff;
  transition: stroke 1s cubic-bezier(0, 0, 0, 1)
}

.header.is-dark:not(.is-scrolled) .header__logo svg path,
.header.is-dark:not(.is-scrolled) .header__logo svg ellipse {
  fill: #000
}

.header.is-dark:not(.is-scrolled) .header__logo svg circle {
  stroke: #000
}

@media (max-width: 959.98px) {
  .v-application.has-sidebar .header__logo {
    margin: 0 auto
  }
}

@media (min-width: 960px) {
  .v-application.has-sidebar .header__logo {
    display: none
  }
}

.header__nav {
  display: flex;
  justify-content: flex-end;
  align-content: center;
  align-items: center
}

@media (max-width: 959.98px) {
  .header__nav {
    position: absolute;
    right: 20px;
    top: 10px
  }
}

@media (min-width: 960px) {
  .header__nav {
    width: 60%;
    margin-left: auto
  }
}

.header__nav .v-btn--menu {
  transition: background-color .5s cubic-bezier(0, 0, 0, 1);
  width: 40px;
  border-radius: 50%;
  margin-left: 10px
}

@media (min-width: 1920px) {
  .header__nav .v-btn--menu {
    width: 56px
  }
}

.header__nav .v-btn--menu .v-icon {
  transition: color .5s cubic-bezier(0, 0, 0, 1)
}

.header.is-scrolled .header__nav .v-btn--menu {
  background: #ffffff
}

.header.is-scrolled .header__nav .v-btn--menu .v-icon {
  color: #000
}

@media (min-width: 1280px) {
  .header__nav .btn--wallet {
    padding: 0 30px
  }
}

.header__nav .btn--wallet,
.header__nav .v-btn--menu {
  height: 40px
}

@media (min-width: 1920px) {

  .header__nav .btn--wallet,
  .header__nav .v-btn--menu {
    height: 56px
  }
}

ul.header__social {
  list-style: none;
  margin: 0 30px 0 0;
  padding: 0 !important;
  display: inline-block
}

ul.header__social .social__item {
  display: inline-block;
  margin: 0 10px 0 20px
}

ul.header__social .social__link {
  text-decoration: none;
  transition: opacity .3s cubic-bezier(0, 0, 0, 1)
}

ul.header__social .social__link:hover {
  opacity: .6
}

ul.header__social .v-icon {
  color: #fff;
  font-size: 2rem
}

.v-container {
  width: 100%;
  padding: 16px;
  z-index: 1;
  margin-right: auto;
  margin-left: auto
}

@media (min-width: 960px) {
  .v-container {
    max-width: 900px
  }
}

@media (min-width: 1280px) {
  .v-container {
    max-width: 1200px
  }
}

@media (min-width: 1920px) {
  .v-container {
    max-width: 1800px
  }
}

@media (min-width: 2560px) {
  .v-container {
    max-width: 2400px
  }
}

.v-container--fluid {
  max-width: 100%
}

.v-row {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 auto;
  margin: -12px
}

.v-row+.row {
  margin-top: 12px
}

.v-row+.row--dense {
  margin-top: 4px
}

.v-row--dense {
  margin: -4px
}

.v-row--dense>.v-col,
.v-row--dense>[class*=v-col-] {
  padding: 4px
}

.v-row.v-row--no-gutters {
  margin: 0
}

.v-row.v-row--no-gutters>.v-col,
.v-row.v-row--no-gutters>[class*=v-col-] {
  padding: 0
}

.v-col-xxl,
.v-col-xxl-auto,
.v-col-xxl-12,
.v-col-xxl-11,
.v-col-xxl-10,
.v-col-xxl-9,
.v-col-xxl-8,
.v-col-xxl-7,
.v-col-xxl-6,
.v-col-xxl-5,
.v-col-xxl-4,
.v-col-xxl-3,
.v-col-xxl-2,
.v-col-xxl-1,
.v-col-xl,
.v-col-xl-auto,
.v-col-xl-12,
.v-col-xl-11,
.v-col-xl-10,
.v-col-xl-9,
.v-col-xl-8,
.v-col-xl-7,
.v-col-xl-6,
.v-col-xl-5,
.v-col-xl-4,
.v-col-xl-3,
.v-col-xl-2,
.v-col-xl-1,
.v-col-lg,
.v-col-lg-auto,
.v-col-lg-12,
.v-col-lg-11,
.v-col-lg-10,
.v-col-lg-9,
.v-col-lg-8,
.v-col-lg-7,
.v-col-lg-6,
.v-col-lg-5,
.v-col-lg-4,
.v-col-lg-3,
.v-col-lg-2,
.v-col-lg-1,
.v-col-md,
.v-col-md-auto,
.v-col-md-12,
.v-col-md-11,
.v-col-md-10,
.v-col-md-9,
.v-col-md-8,
.v-col-md-7,
.v-col-md-6,
.v-col-md-5,
.v-col-md-4,
.v-col-md-3,
.v-col-md-2,
.v-col-md-1,
.v-col-sm,
.v-col-sm-auto,
.v-col-sm-12,
.v-col-sm-11,
.v-col-sm-10,
.v-col-sm-9,
.v-col-sm-8,
.v-col-sm-7,
.v-col-sm-6,
.v-col-sm-5,
.v-col-sm-4,
.v-col-sm-3,
.v-col-sm-2,
.v-col-sm-1,
.v-col,
.v-col-auto,
.v-col-12,
.v-col-11,
.v-col-10,
.v-col-9,
.v-col-8,
.v-col-7,
.v-col-6,
.v-col-5,
.v-col-4,
.v-col-3,
.v-col-2,
.v-col-1 {
  width: 100%;
  padding: 12px
}

.v-col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%
}

.v-col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%
}

.v-col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%
}

.v-col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%
}

.v-col-3 {
  flex: 0 0 25%;
  max-width: 25%
}

.v-col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%
}

.v-col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%
}

.v-col-6 {
  flex: 0 0 50%;
  max-width: 50%
}

.v-col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%
}

.v-col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%
}

.v-col-9 {
  flex: 0 0 75%;
  max-width: 75%
}

.v-col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%
}

.v-col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%
}

.v-col-12 {
  flex: 0 0 100%;
  max-width: 100%
}

.offset-1.v-locale--is-ltr,
.v-locale--is-ltr .offset-1 {
  margin-left: 8.3333333333%
}

.offset-1.v-locale--is-rtl,
.v-locale--is-rtl .offset-1 {
  margin-right: 8.3333333333%
}

.offset-2.v-locale--is-ltr,
.v-locale--is-ltr .offset-2 {
  margin-left: 16.6666666667%
}

.offset-2.v-locale--is-rtl,
.v-locale--is-rtl .offset-2 {
  margin-right: 16.6666666667%
}

.offset-3.v-locale--is-ltr,
.v-locale--is-ltr .offset-3 {
  margin-left: 25%
}

.offset-3.v-locale--is-rtl,
.v-locale--is-rtl .offset-3 {
  margin-right: 25%
}

.offset-4.v-locale--is-ltr,
.v-locale--is-ltr .offset-4 {
  margin-left: 33.3333333333%
}

.offset-4.v-locale--is-rtl,
.v-locale--is-rtl .offset-4 {
  margin-right: 33.3333333333%
}

.offset-5.v-locale--is-ltr,
.v-locale--is-ltr .offset-5 {
  margin-left: 41.6666666667%
}

.offset-5.v-locale--is-rtl,
.v-locale--is-rtl .offset-5 {
  margin-right: 41.6666666667%
}

.offset-6.v-locale--is-ltr,
.v-locale--is-ltr .offset-6 {
  margin-left: 50%
}

.offset-6.v-locale--is-rtl,
.v-locale--is-rtl .offset-6 {
  margin-right: 50%
}

.offset-7.v-locale--is-ltr,
.v-locale--is-ltr .offset-7 {
  margin-left: 58.3333333333%
}

.offset-7.v-locale--is-rtl,
.v-locale--is-rtl .offset-7 {
  margin-right: 58.3333333333%
}

.offset-8.v-locale--is-ltr,
.v-locale--is-ltr .offset-8 {
  margin-left: 66.6666666667%
}

.offset-8.v-locale--is-rtl,
.v-locale--is-rtl .offset-8 {
  margin-right: 66.6666666667%
}

.offset-9.v-locale--is-ltr,
.v-locale--is-ltr .offset-9 {
  margin-left: 75%
}

.offset-9.v-locale--is-rtl,
.v-locale--is-rtl .offset-9 {
  margin-right: 75%
}

.offset-10.v-locale--is-ltr,
.v-locale--is-ltr .offset-10 {
  margin-left: 83.3333333333%
}

.offset-10.v-locale--is-rtl,
.v-locale--is-rtl .offset-10 {
  margin-right: 83.3333333333%
}

.offset-11.v-locale--is-ltr,
.v-locale--is-ltr .offset-11 {
  margin-left: 91.6666666667%
}

.offset-11.v-locale--is-rtl,
.v-locale--is-rtl .offset-11 {
  margin-right: 91.6666666667%
}

@media (min-width: 600px) {
  .v-col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%
  }

  .v-col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%
  }

  .v-col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%
  }

  .v-col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%
  }

  .v-col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%
  }

  .v-col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%
  }

  .v-col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%
  }

  .v-col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%
  }

  .v-col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%
  }

  .v-col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%
  }

  .v-col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%
  }

  .v-col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%
  }

  .v-col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%
  }

  .v-col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%
  }

  .offset-sm-0.v-locale--is-ltr,
  .v-locale--is-ltr .offset-sm-0 {
    margin-left: 0
  }

  .offset-sm-0.v-locale--is-rtl,
  .v-locale--is-rtl .offset-sm-0 {
    margin-right: 0
  }

  .offset-sm-1.v-locale--is-ltr,
  .v-locale--is-ltr .offset-sm-1 {
    margin-left: 8.3333333333%
  }

  .offset-sm-1.v-locale--is-rtl,
  .v-locale--is-rtl .offset-sm-1 {
    margin-right: 8.3333333333%
  }

  .offset-sm-2.v-locale--is-ltr,
  .v-locale--is-ltr .offset-sm-2 {
    margin-left: 16.6666666667%
  }

  .offset-sm-2.v-locale--is-rtl,
  .v-locale--is-rtl .offset-sm-2 {
    margin-right: 16.6666666667%
  }

  .offset-sm-3.v-locale--is-ltr,
  .v-locale--is-ltr .offset-sm-3 {
    margin-left: 25%
  }

  .offset-sm-3.v-locale--is-rtl,
  .v-locale--is-rtl .offset-sm-3 {
    margin-right: 25%
  }

  .offset-sm-4.v-locale--is-ltr,
  .v-locale--is-ltr .offset-sm-4 {
    margin-left: 33.3333333333%
  }

  .offset-sm-4.v-locale--is-rtl,
  .v-locale--is-rtl .offset-sm-4 {
    margin-right: 33.3333333333%
  }

  .offset-sm-5.v-locale--is-ltr,
  .v-locale--is-ltr .offset-sm-5 {
    margin-left: 41.6666666667%
  }

  .offset-sm-5.v-locale--is-rtl,
  .v-locale--is-rtl .offset-sm-5 {
    margin-right: 41.6666666667%
  }

  .offset-sm-6.v-locale--is-ltr,
  .v-locale--is-ltr .offset-sm-6 {
    margin-left: 50%
  }

  .offset-sm-6.v-locale--is-rtl,
  .v-locale--is-rtl .offset-sm-6 {
    margin-right: 50%
  }

  .offset-sm-7.v-locale--is-ltr,
  .v-locale--is-ltr .offset-sm-7 {
    margin-left: 58.3333333333%
  }

  .offset-sm-7.v-locale--is-rtl,
  .v-locale--is-rtl .offset-sm-7 {
    margin-right: 58.3333333333%
  }

  .offset-sm-8.v-locale--is-ltr,
  .v-locale--is-ltr .offset-sm-8 {
    margin-left: 66.6666666667%
  }

  .offset-sm-8.v-locale--is-rtl,
  .v-locale--is-rtl .offset-sm-8 {
    margin-right: 66.6666666667%
  }

  .offset-sm-9.v-locale--is-ltr,
  .v-locale--is-ltr .offset-sm-9 {
    margin-left: 75%
  }

  .offset-sm-9.v-locale--is-rtl,
  .v-locale--is-rtl .offset-sm-9 {
    margin-right: 75%
  }

  .offset-sm-10.v-locale--is-ltr,
  .v-locale--is-ltr .offset-sm-10 {
    margin-left: 83.3333333333%
  }

  .offset-sm-10.v-locale--is-rtl,
  .v-locale--is-rtl .offset-sm-10 {
    margin-right: 83.3333333333%
  }

  .offset-sm-11.v-locale--is-ltr,
  .v-locale--is-ltr .offset-sm-11 {
    margin-left: 91.6666666667%
  }

  .offset-sm-11.v-locale--is-rtl,
  .v-locale--is-rtl .offset-sm-11 {
    margin-right: 91.6666666667%
  }
}

@media (min-width: 960px) {
  .v-col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%
  }

  .v-col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%
  }

  .v-col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%
  }

  .v-col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%
  }

  .v-col-md-3 {
    flex: 0 0 25%;
    max-width: 25%
  }

  .v-col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%
  }

  .v-col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%
  }

  .v-col-md-6 {
    flex: 0 0 50%;
    max-width: 50%
  }

  .v-col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%
  }

  .v-col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%
  }

  .v-col-md-9 {
    flex: 0 0 75%;
    max-width: 75%
  }

  .v-col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%
  }

  .v-col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%
  }

  .v-col-md-12 {
    flex: 0 0 100%;
    max-width: 100%
  }

  .offset-md-0.v-locale--is-ltr,
  .v-locale--is-ltr .offset-md-0 {
    margin-left: 0
  }

  .offset-md-0.v-locale--is-rtl,
  .v-locale--is-rtl .offset-md-0 {
    margin-right: 0
  }

  .offset-md-1.v-locale--is-ltr,
  .v-locale--is-ltr .offset-md-1 {
    margin-left: 8.3333333333%
  }

  .offset-md-1.v-locale--is-rtl,
  .v-locale--is-rtl .offset-md-1 {
    margin-right: 8.3333333333%
  }

  .offset-md-2.v-locale--is-ltr,
  .v-locale--is-ltr .offset-md-2 {
    margin-left: 16.6666666667%
  }

  .offset-md-2.v-locale--is-rtl,
  .v-locale--is-rtl .offset-md-2 {
    margin-right: 16.6666666667%
  }

  .offset-md-3.v-locale--is-ltr,
  .v-locale--is-ltr .offset-md-3 {
    margin-left: 25%
  }

  .offset-md-3.v-locale--is-rtl,
  .v-locale--is-rtl .offset-md-3 {
    margin-right: 25%
  }

  .offset-md-4.v-locale--is-ltr,
  .v-locale--is-ltr .offset-md-4 {
    margin-left: 33.3333333333%
  }

  .offset-md-4.v-locale--is-rtl,
  .v-locale--is-rtl .offset-md-4 {
    margin-right: 33.3333333333%
  }

  .offset-md-5.v-locale--is-ltr,
  .v-locale--is-ltr .offset-md-5 {
    margin-left: 41.6666666667%
  }

  .offset-md-5.v-locale--is-rtl,
  .v-locale--is-rtl .offset-md-5 {
    margin-right: 41.6666666667%
  }

  .offset-md-6.v-locale--is-ltr,
  .v-locale--is-ltr .offset-md-6 {
    margin-left: 50%
  }

  .offset-md-6.v-locale--is-rtl,
  .v-locale--is-rtl .offset-md-6 {
    margin-right: 50%
  }

  .offset-md-7.v-locale--is-ltr,
  .v-locale--is-ltr .offset-md-7 {
    margin-left: 58.3333333333%
  }

  .offset-md-7.v-locale--is-rtl,
  .v-locale--is-rtl .offset-md-7 {
    margin-right: 58.3333333333%
  }

  .offset-md-8.v-locale--is-ltr,
  .v-locale--is-ltr .offset-md-8 {
    margin-left: 66.6666666667%
  }

  .offset-md-8.v-locale--is-rtl,
  .v-locale--is-rtl .offset-md-8 {
    margin-right: 66.6666666667%
  }

  .offset-md-9.v-locale--is-ltr,
  .v-locale--is-ltr .offset-md-9 {
    margin-left: 75%
  }

  .offset-md-9.v-locale--is-rtl,
  .v-locale--is-rtl .offset-md-9 {
    margin-right: 75%
  }

  .offset-md-10.v-locale--is-ltr,
  .v-locale--is-ltr .offset-md-10 {
    margin-left: 83.3333333333%
  }

  .offset-md-10.v-locale--is-rtl,
  .v-locale--is-rtl .offset-md-10 {
    margin-right: 83.3333333333%
  }

  .offset-md-11.v-locale--is-ltr,
  .v-locale--is-ltr .offset-md-11 {
    margin-left: 91.6666666667%
  }

  .offset-md-11.v-locale--is-rtl,
  .v-locale--is-rtl .offset-md-11 {
    margin-right: 91.6666666667%
  }
}

@media (min-width: 1280px) {
  .v-col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%
  }

  .v-col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%
  }

  .v-col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%
  }

  .v-col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%
  }

  .v-col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%
  }

  .v-col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%
  }

  .v-col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%
  }

  .v-col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%
  }

  .v-col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%
  }

  .v-col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%
  }

  .v-col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%
  }

  .v-col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%
  }

  .v-col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%
  }

  .v-col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%
  }

  .offset-lg-0.v-locale--is-ltr,
  .v-locale--is-ltr .offset-lg-0 {
    margin-left: 0
  }

  .offset-lg-0.v-locale--is-rtl,
  .v-locale--is-rtl .offset-lg-0 {
    margin-right: 0
  }

  .offset-lg-1.v-locale--is-ltr,
  .v-locale--is-ltr .offset-lg-1 {
    margin-left: 8.3333333333%
  }

  .offset-lg-1.v-locale--is-rtl,
  .v-locale--is-rtl .offset-lg-1 {
    margin-right: 8.3333333333%
  }

  .offset-lg-2.v-locale--is-ltr,
  .v-locale--is-ltr .offset-lg-2 {
    margin-left: 16.6666666667%
  }

  .offset-lg-2.v-locale--is-rtl,
  .v-locale--is-rtl .offset-lg-2 {
    margin-right: 16.6666666667%
  }

  .offset-lg-3.v-locale--is-ltr,
  .v-locale--is-ltr .offset-lg-3 {
    margin-left: 25%
  }

  .offset-lg-3.v-locale--is-rtl,
  .v-locale--is-rtl .offset-lg-3 {
    margin-right: 25%
  }

  .offset-lg-4.v-locale--is-ltr,
  .v-locale--is-ltr .offset-lg-4 {
    margin-left: 33.3333333333%
  }

  .offset-lg-4.v-locale--is-rtl,
  .v-locale--is-rtl .offset-lg-4 {
    margin-right: 33.3333333333%
  }

  .offset-lg-5.v-locale--is-ltr,
  .v-locale--is-ltr .offset-lg-5 {
    margin-left: 41.6666666667%
  }

  .offset-lg-5.v-locale--is-rtl,
  .v-locale--is-rtl .offset-lg-5 {
    margin-right: 41.6666666667%
  }

  .offset-lg-6.v-locale--is-ltr,
  .v-locale--is-ltr .offset-lg-6 {
    margin-left: 50%
  }

  .offset-lg-6.v-locale--is-rtl,
  .v-locale--is-rtl .offset-lg-6 {
    margin-right: 50%
  }

  .offset-lg-7.v-locale--is-ltr,
  .v-locale--is-ltr .offset-lg-7 {
    margin-left: 58.3333333333%
  }

  .offset-lg-7.v-locale--is-rtl,
  .v-locale--is-rtl .offset-lg-7 {
    margin-right: 58.3333333333%
  }

  .offset-lg-8.v-locale--is-ltr,
  .v-locale--is-ltr .offset-lg-8 {
    margin-left: 66.6666666667%
  }

  .offset-lg-8.v-locale--is-rtl,
  .v-locale--is-rtl .offset-lg-8 {
    margin-right: 66.6666666667%
  }

  .offset-lg-9.v-locale--is-ltr,
  .v-locale--is-ltr .offset-lg-9 {
    margin-left: 75%
  }

  .offset-lg-9.v-locale--is-rtl,
  .v-locale--is-rtl .offset-lg-9 {
    margin-right: 75%
  }

  .offset-lg-10.v-locale--is-ltr,
  .v-locale--is-ltr .offset-lg-10 {
    margin-left: 83.3333333333%
  }

  .offset-lg-10.v-locale--is-rtl,
  .v-locale--is-rtl .offset-lg-10 {
    margin-right: 83.3333333333%
  }

  .offset-lg-11.v-locale--is-ltr,
  .v-locale--is-ltr .offset-lg-11 {
    margin-left: 91.6666666667%
  }

  .offset-lg-11.v-locale--is-rtl,
  .v-locale--is-rtl .offset-lg-11 {
    margin-right: 91.6666666667%
  }
}

@media (min-width: 1920px) {
  .v-col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%
  }

  .v-col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%
  }

  .v-col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%
  }

  .v-col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%
  }

  .v-col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%
  }

  .v-col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%
  }

  .v-col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%
  }

  .v-col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%
  }

  .v-col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%
  }

  .v-col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%
  }

  .v-col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%
  }

  .v-col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%
  }

  .v-col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%
  }

  .v-col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%
  }

  .offset-xl-0.v-locale--is-ltr,
  .v-locale--is-ltr .offset-xl-0 {
    margin-left: 0
  }

  .offset-xl-0.v-locale--is-rtl,
  .v-locale--is-rtl .offset-xl-0 {
    margin-right: 0
  }

  .offset-xl-1.v-locale--is-ltr,
  .v-locale--is-ltr .offset-xl-1 {
    margin-left: 8.3333333333%
  }

  .offset-xl-1.v-locale--is-rtl,
  .v-locale--is-rtl .offset-xl-1 {
    margin-right: 8.3333333333%
  }

  .offset-xl-2.v-locale--is-ltr,
  .v-locale--is-ltr .offset-xl-2 {
    margin-left: 16.6666666667%
  }

  .offset-xl-2.v-locale--is-rtl,
  .v-locale--is-rtl .offset-xl-2 {
    margin-right: 16.6666666667%
  }

  .offset-xl-3.v-locale--is-ltr,
  .v-locale--is-ltr .offset-xl-3 {
    margin-left: 25%
  }

  .offset-xl-3.v-locale--is-rtl,
  .v-locale--is-rtl .offset-xl-3 {
    margin-right: 25%
  }

  .offset-xl-4.v-locale--is-ltr,
  .v-locale--is-ltr .offset-xl-4 {
    margin-left: 33.3333333333%
  }

  .offset-xl-4.v-locale--is-rtl,
  .v-locale--is-rtl .offset-xl-4 {
    margin-right: 33.3333333333%
  }

  .offset-xl-5.v-locale--is-ltr,
  .v-locale--is-ltr .offset-xl-5 {
    margin-left: 41.6666666667%
  }

  .offset-xl-5.v-locale--is-rtl,
  .v-locale--is-rtl .offset-xl-5 {
    margin-right: 41.6666666667%
  }

  .offset-xl-6.v-locale--is-ltr,
  .v-locale--is-ltr .offset-xl-6 {
    margin-left: 50%
  }

  .offset-xl-6.v-locale--is-rtl,
  .v-locale--is-rtl .offset-xl-6 {
    margin-right: 50%
  }

  .offset-xl-7.v-locale--is-ltr,
  .v-locale--is-ltr .offset-xl-7 {
    margin-left: 58.3333333333%
  }

  .offset-xl-7.v-locale--is-rtl,
  .v-locale--is-rtl .offset-xl-7 {
    margin-right: 58.3333333333%
  }

  .offset-xl-8.v-locale--is-ltr,
  .v-locale--is-ltr .offset-xl-8 {
    margin-left: 66.6666666667%
  }

  .offset-xl-8.v-locale--is-rtl,
  .v-locale--is-rtl .offset-xl-8 {
    margin-right: 66.6666666667%
  }

  .offset-xl-9.v-locale--is-ltr,
  .v-locale--is-ltr .offset-xl-9 {
    margin-left: 75%
  }

  .offset-xl-9.v-locale--is-rtl,
  .v-locale--is-rtl .offset-xl-9 {
    margin-right: 75%
  }

  .offset-xl-10.v-locale--is-ltr,
  .v-locale--is-ltr .offset-xl-10 {
    margin-left: 83.3333333333%
  }

  .offset-xl-10.v-locale--is-rtl,
  .v-locale--is-rtl .offset-xl-10 {
    margin-right: 83.3333333333%
  }

  .offset-xl-11.v-locale--is-ltr,
  .v-locale--is-ltr .offset-xl-11 {
    margin-left: 91.6666666667%
  }

  .offset-xl-11.v-locale--is-rtl,
  .v-locale--is-rtl .offset-xl-11 {
    margin-right: 91.6666666667%
  }
}

@media (min-width: 2560px) {
  .v-col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%
  }

  .v-col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%
  }

  .v-col-xxl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%
  }

  .v-col-xxl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%
  }

  .v-col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%
  }

  .v-col-xxl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%
  }

  .v-col-xxl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%
  }

  .v-col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%
  }

  .v-col-xxl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%
  }

  .v-col-xxl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%
  }

  .v-col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%
  }

  .v-col-xxl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%
  }

  .v-col-xxl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%
  }

  .v-col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%
  }

  .offset-xxl-0.v-locale--is-ltr,
  .v-locale--is-ltr .offset-xxl-0 {
    margin-left: 0
  }

  .offset-xxl-0.v-locale--is-rtl,
  .v-locale--is-rtl .offset-xxl-0 {
    margin-right: 0
  }

  .offset-xxl-1.v-locale--is-ltr,
  .v-locale--is-ltr .offset-xxl-1 {
    margin-left: 8.3333333333%
  }

  .offset-xxl-1.v-locale--is-rtl,
  .v-locale--is-rtl .offset-xxl-1 {
    margin-right: 8.3333333333%
  }

  .offset-xxl-2.v-locale--is-ltr,
  .v-locale--is-ltr .offset-xxl-2 {
    margin-left: 16.6666666667%
  }

  .offset-xxl-2.v-locale--is-rtl,
  .v-locale--is-rtl .offset-xxl-2 {
    margin-right: 16.6666666667%
  }

  .offset-xxl-3.v-locale--is-ltr,
  .v-locale--is-ltr .offset-xxl-3 {
    margin-left: 25%
  }

  .offset-xxl-3.v-locale--is-rtl,
  .v-locale--is-rtl .offset-xxl-3 {
    margin-right: 25%
  }

  .offset-xxl-4.v-locale--is-ltr,
  .v-locale--is-ltr .offset-xxl-4 {
    margin-left: 33.3333333333%
  }

  .offset-xxl-4.v-locale--is-rtl,
  .v-locale--is-rtl .offset-xxl-4 {
    margin-right: 33.3333333333%
  }

  .offset-xxl-5.v-locale--is-ltr,
  .v-locale--is-ltr .offset-xxl-5 {
    margin-left: 41.6666666667%
  }

  .offset-xxl-5.v-locale--is-rtl,
  .v-locale--is-rtl .offset-xxl-5 {
    margin-right: 41.6666666667%
  }

  .offset-xxl-6.v-locale--is-ltr,
  .v-locale--is-ltr .offset-xxl-6 {
    margin-left: 50%
  }

  .offset-xxl-6.v-locale--is-rtl,
  .v-locale--is-rtl .offset-xxl-6 {
    margin-right: 50%
  }

  .offset-xxl-7.v-locale--is-ltr,
  .v-locale--is-ltr .offset-xxl-7 {
    margin-left: 58.3333333333%
  }

  .offset-xxl-7.v-locale--is-rtl,
  .v-locale--is-rtl .offset-xxl-7 {
    margin-right: 58.3333333333%
  }

  .offset-xxl-8.v-locale--is-ltr,
  .v-locale--is-ltr .offset-xxl-8 {
    margin-left: 66.6666666667%
  }

  .offset-xxl-8.v-locale--is-rtl,
  .v-locale--is-rtl .offset-xxl-8 {
    margin-right: 66.6666666667%
  }

  .offset-xxl-9.v-locale--is-ltr,
  .v-locale--is-ltr .offset-xxl-9 {
    margin-left: 75%
  }

  .offset-xxl-9.v-locale--is-rtl,
  .v-locale--is-rtl .offset-xxl-9 {
    margin-right: 75%
  }

  .offset-xxl-10.v-locale--is-ltr,
  .v-locale--is-ltr .offset-xxl-10 {
    margin-left: 83.3333333333%
  }

  .offset-xxl-10.v-locale--is-rtl,
  .v-locale--is-rtl .offset-xxl-10 {
    margin-right: 83.3333333333%
  }

  .offset-xxl-11.v-locale--is-ltr,
  .v-locale--is-ltr .offset-xxl-11 {
    margin-left: 91.6666666667%
  }

  .offset-xxl-11.v-locale--is-rtl,
  .v-locale--is-rtl .offset-xxl-11 {
    margin-right: 91.6666666667%
  }
}

.v-overlay-container {
  contain: layout;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  display: contents
}

.v-overlay-scroll-blocked {
  padding-inline-end: var(--v-scrollbar-offset);
  overflow-y: hidden !important
}

html.v-overlay-scroll-blocked {
  position: fixed;
  top: var(--v-body-scroll-y);
  left: var(--v-body-scroll-x);
  width: 100%;
  height: 100%
}

.v-overlay {
  border-radius: inherit;
  display: flex;
  left: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0
}

.v-overlay__content {
  outline: none;
  position: absolute;
  pointer-events: auto;
  contain: layout
}

.v-overlay__scrim {
  pointer-events: auto;
  background: rgb(var(--v-theme-on-surface));
  border-radius: inherit;
  bottom: 0;
  left: 0;
  opacity: 32%;
  position: fixed;
  right: 0;
  top: 0
}

.v-overlay--absolute,
.v-overlay--contained .v-overlay__scrim {
  position: absolute
}

.v-overlay--scroll-blocked {
  padding-inline-end: var(--v-scrollbar-offset)
}

.ball-background {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  user-select: none;
  pointer-events: none;
  overflow: hidden
}

.ball-background .ball {
  animation: floating-item 5s infinite ease-in-out;
  position: absolute;
  height: auto
}

.ball-background .ball--1 {
  right: 42.73%;
  top: calc(var(--vh, 1vh) * 32);
  width: 900px;
  height: 900px;
  border-radius: 50%;
  background: rgba(0, 79, 137, .6);
  opacity: .25;
  filter: blur(200px);
  animation-delay: .5s;
  animation-duration: 5.2s
}

.ball-background .ball--2 {
  left: 54.19%;
  top: calc(var(--vh, 1vh) * 30);
  width: 1300px;
  height: 1300px;
  border-radius: 50%;
  background: #3300ff;
  opacity: .2;
  filter: blur(200px);
  animation-delay: 1s;
  animation-duration: 4.6s
}

.ball-background .ball--3 {
  left: 40.22%;
  top: calc(var(--vh, 1vh) * -10);
  width: 1380px;
  height: 1380px;
  border-radius: 50%;
  background: #5364ff;
  opacity: .15;
  filter: blur(100px);
  animation-delay: .7s
}

.ball-background .ball--4 {
  width: 960px;
  height: 960px;
  top: calc(var(--vh, 1vh) * 70);
  left: 24%;
  border-radius: 50%;
  background: #3300ff;
  opacity: .1;
  filter: blur(200px);
  animation-delay: .7s
}

.ball-background .ball--5 {
  width: 1200px;
  height: 1200px;
  top: calc(var(--vh, 1vh) * -30);
  left: -40%;
  border-radius: 50%;
  background: #e57bff;
  opacity: .1;
  filter: blur(300px);
  animation-delay: .5s
}

.ball-background .ball--green {
  width: 290px;
  height: 290px;
  top: -10%;
  right: 10%;
  animation-delay: 1s;
  animation-duration: 4.6s;
  opacity: .1
}

.ball-background .ball--blue {
  width: 414px;
  height: 414px;
  top: calc(var(--vh, 1vh) * 10);
  left: 0;
  animation-duration: 4.6s;
  opacity: .1
}

.ball-background .ball--purple {
  width: 430px;
  height: 430px;
  top: calc(var(--vh, 1vh) * 90);
  right: -10%;
  opacity: .1
}

@keyframes floating-item {
  0% {
    transform: translate(0)
  }

  65% {
    transform: translateY(40px)
  }

  to {
    transform: translate(0)
  }
}

.v-img {
  --v-theme-overlay-multiplier: 3;
  z-index: 0
}

.v-img--booting .v-responsive__sizer {
  transition: none
}

.v-img__img,
.v-img__picture,
.v-img__gradient,
.v-img__placeholder,
.v-img__error {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%
}

.v-img__img--preload {
  filter: blur(4px)
}

.v-img__img--contain {
  object-fit: contain
}

.v-img__img--cover {
  object-fit: cover
}

.v-img__gradient {
  background-repeat: no-repeat
}

.v-responsive {
  display: flex;
  flex: 1 0 auto;
  max-height: 100%;
  max-width: 100%;
  overflow: hidden;
  position: relative
}

.v-responsive__content {
  flex: 1 0 0px;
  max-width: 100%
}

.v-responsive__sizer~.v-responsive__content {
  margin-inline-start: -100%
}

.v-responsive__sizer {
  flex: 1 0 0px;
  transition: padding-bottom .2s cubic-bezier(.4, 0, .2, 1);
  pointer-events: none
}

.sidebar__header {
  border-bottom: 1px solid #282c38
}

.sidebar__header .v-btn.sidebar__close {
  position: absolute;
  top: 5px;
  right: 5px;
  background: transparent;
  box-shadow: none
}

.sidebar__logo {
  display: inline-block;
  width: 68px;
  height: 31px
}

.sidebar__logo svg {
  width: 100%;
  height: auto
}

.sidebar__footer .badge__item {
  display: inline-block;
  margin-right: 10px
}

.sidebar__footer .badge__link,
.sidebar__footer .badge__link .v-img {
  width: 35px;
  height: 35px
}

.badge__list {
  margin: 0 0 20px
}

.navigation__link {
  display: flex;
  background: transparent;
  justify-content: flex-start;
  align-content: center;
  padding: .75rem 1rem;
  border-radius: 50px;
  color: #757b8c;
  position: relative;
  overflow: hidden;
  transition: color .3s cubic-bezier(0, 0, 0, 1), background-color .3s cubic-bezier(0, 0, 0, 1);
  cursor: pointer
}

.navigation__link>* {
  position: relative;
  z-index: 2
}

.navigation__link svg {
  width: 24px;
  margin-right: 10px
}

.navigation__link path {
  fill: #757b8c;
  transition: fill .3s cubic-bezier(0, 0, 0, 1)
}

.navigation__link:hover,
.navigation__link.router-link-active {
  color: #fff
}

.navigation__link:hover path,
.navigation__link.router-link-active path {
  fill: #4340fc
}



.navigation__link .v-icon {
  margin-right: .5em
}

.profile-modal {
  background-color: #282c38 !important;
  left: 70px;
  top: -70px;
  position: absolute;
  height: 132px
}

.profile-modal .btn-connect-wallet {
  background-color: #282c38 !important
}

.sidebar__footer {
  background-color: #2f285d;
  margin-top: auto
}

.sidebar__footer .footer__content {
  display: flex;
  flex-direction: column;
  justify-content: center
}

.sidebar__footer .footer__image {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px
}

.sidebar__footer .footer__wallet {
  color: #757b8c;
  font-size: .875rem
}

.sidebar__footer .footer__button {
  width: 36px;
  min-width: 36px;
  padding: 0;
  color: #757b8c
}

.sidebar__footer .footer__button .v-icon {
  font-size: 2rem
}

.sidebar__footer .footer__button .active {
  color: #22c993
}

.v-overlay__content.footer__menu {
  border-radius: 20px;
  background-color: #2c2c4b;
  transform: translate(25px, 21px)
}

@media (max-width: 599.98px) {
  .v-overlay__content.footer__menu {
    box-shadow: 0 0 10px #0003;
    transform: translateY(-43px)
  }
}

@media (max-width: 408.98px) {
  .v-overlay__content.footer__menu {
    transform: translate(33vw, -43px)
  }
}

@media (max-width: 330.98px) {
  .v-overlay__content.footer__menu {
    transform: translate(21vw, -43px)
  }
}

.v-overlay__content.footer__menu .footer__list {
  padding: 15px 5px
}

.v-overlay__content.footer__menu .footer__link {
  font-weight: 400;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  display: flex;
  padding: .75rem 1rem;
  font-size: .875rem;
  color: #757b8c;
  transition: color .3s cubic-bezier(0, 0, 0, 1)
}

.v-overlay__content.footer__menu .footer__link .v-icon {
  margin-right: 5px;
  transition: color .3s cubic-bezier(0, 0, 0, 1)
}

.v-overlay__content.footer__menu .footer__link:hover,
.v-overlay__content.footer__menu .footer__link.router-link-active {
  color: #fff;
  background: #282d5d
}

.v-overlay__content.footer__menu .footer__link:hover .v-icon,
.v-overlay__content.footer__menu .footer__link.router-link-active .v-icon {
  color: #4340fc
}

.v-menu .v-overlay__content {
  display: flex;
  flex-direction: column;
  border-radius: 4px
}

.v-menu .v-overlay__content>.v-card,
.v-menu .v-overlay__content>.v-sheet,
.v-menu .v-overlay__content>.v-list {
  background: rgb(var(--v-theme-surface));
  border-radius: inherit;
  overflow: auto;
  height: 100%;
  box-shadow: 0 5px 5px -3px #0003, 0 8px 10px 1px #00000024, 0 3px 14px 2px #0000001f
}

.navigation__link {
  display: flex;
  background: transparent;
  justify-content: flex-start;
  align-content: center;
  padding: .75rem 1rem;
  border-radius: 50px;
  color: #757b8c;
  position: relative;
  overflow: hidden;
  transition: color .3s cubic-bezier(0, 0, 0, 1), background-color .3s cubic-bezier(0, 0, 0, 1)
}

.navigation__link>* {
  position: relative;
  z-index: 2
}

.navigation__link svg {
  width: 24px;
  margin-right: 10px
}

.navigation__link path {
  fill: #757b8c;
  transition: fill .3s cubic-bezier(0, 0, 0, 1)
}

.navigation__link:hover,
.navigation__link.router-link-active {
  color: #fff
}

.navigation__link:hover path,
.navigation__link.router-link-active path {
  fill: #4340fc
}



.navigation__link .v-icon {
  margin-right: .5em
}

.navigation__link--coming-soon {
  cursor: default;
  margin-bottom: .25rem;
  border-radius: 0;
  margin-left: -1rem;
  width: calc(100% + 2rem);
  padding-left: 2rem
}

.navigation__link--coming-soon span,
.navigation__link--coming-soon svg {
  opacity: .2
}

.navigation__link--coming-soon:hover span {
  opacity: 0
}

.navigation__link--coming-soon:hover .coming-soon {
  opacity: 1;
  transform: translateY(0)
}

.navigation__link--coming-soon .coming-soon {
  background: linear-gradient(112.7deg, #3431EA 14.75%, #6D39FF 85.25%);
  color: #fff;
  left: 0;
  opacity: 0;
  padding: .25rem 1.5rem .25rem calc(2rem + 34px);
  pointer-events: none;
  position: absolute;
  transform: translateY(100%);
  transition: transform .3s cubic-bezier(0, 0, 0, 1), opacity .3s ease-in-out;
  user-select: none;
  align-items: center;
  display: flex;
  height: 100%;
  top: 0;
  width: 100%
}

.w-nav-brand {
  filter: invert(1);
}

.footer-logo-2 {
  filter: invert(1);
}
.navigation__link--coming-soon:before {
  display: none
}

:root {
  --sidebar-width: 250px;
  --sidebar-margin: 20px
}

@media (min-width: 1920px) {
  :root {
    --sidebar-width: 320px;
    --sidebar-margin: 30px
  }
}

.sidebar {
  pointer-events: none;
  display: none;
}

.sidebar>* {
  pointer-events: auto
}

.sidebar.is-hidden .sidebar__wrapper {
  transform: translate(calc(-100% - var(--sidebar-margin)))
}

.sidebar .navigation__list {
  list-style: none;
  margin: 0;
  padding: 0
}

.sidebar__toggle.v-btn.v-btn--icon {
  position: fixed;
  top: var(--sidebar-margin);
  left: var(--sidebar-margin);
  border-radius: 100px;
  z-index: 99;
  font-size: .875rem;
  width: 40px;
  height: 40px
}

.sidebar__wrapper {
  margin: var(--sidebar-margin);
  transition: transform .3s ease-in-out;
  background-color: #2c2c4b;
  color: #fff;
  width: 100%;
  max-width: var(--sidebar-width);
  height: calc((var(--vh, 1vh) * 100) - var(--sidebar-margin) * 2);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  z-index: 100
}

.sidebar__main {
  overflow: auto
}

.sidebar__header,
.sidebar__main,
.sidebar__footer {
  padding: 1rem
}

.sidebar__header {
  padding-left: 2rem
}

.base-full-page-loader {
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  position: fixed;
  z-index: 101;
  background: #141518;
  display: flex;
  justify-items: center;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column
}

.base-full-page-loader .loader__logo {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 100%;
  height: 125px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)
}

.base-full-page-loader .loader__logo svg {
  width: 100%;
  height: 100%
}

.base-full-page-loader .loader__content {
  width: 350px;
  height: 350px;
  position: relative
}

.base-full-page-loader .loader__content:before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)
}

.base-full-page-loader .loader__image {
  width: 100%;
  height: 100%;
  display: block;
  animation: rotate 5s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.base-full-page-loader .loader__text {
  font-size: 1.25rem;
  margin-top: 0;
  text-align: center;
  color: #fff;
  font-family: filson-soft, sans-serif
}





.icon-double-chevron-left:before {
  content: "\e912"
}

.icon-sidebar:before {
  content: "\e911"
}

.icon-double-chevron-right:before {
  content: "\e911"
}

.icon-twitter:before {
  content: "\e90d"
}

.icon-instagram:before {
  content: "\e90e"
}

.icon-foundation:before {
  content: "\e90f"
}

.icon-medium:before {
  content: "\e910"
}

.icon-discord:before {
  content: "\e908"
}

.icon-opensea:before {
  content: "\e909"
}

.icon-profile:before {
  content: "\e90b"
}

.icon-disconnect:before {
  content: "\e90c"
}

.icon-chevron-down:before {
  content: "\e903"
}

.icon-chevron-left:before {
  content: "\e904"
}

.icon-chevron-right:before {
  content: "\e905"
}

.icon-chevron-up:before {
  content: "\e906"
}

.icon-arrow-square-left:before {
  content: "\e900"
}

.icon-arrow-square-right:before {
  content: "\e901"
}

.icon-bell:before {
  content: "\e902"
}

.icon-cross:before {
  content: "\e907"
}

.icon-three-dots:before {
  content: "\e90a"
}

.v-btn {
  text-transform: none;
  border-radius: 5px;
  letter-spacing: 0;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: .04rem;
  box-shadow: none !important
}

.v-btn--rounded {
  border-radius: 30px
}

.v-btn--success {
  background: #20ce50;
  color: #fff
}

.v-btn--warning {
  background: #FFD747;
  color: #000
}

.v-btn--danger {
  background: #FF5D5D;
  color: #fff
}

.v-btn--disabled,
.v-btn:disabled {
  color: #26283a;
  background-color: #8a94a6
}

.v-btn.short,
.v-btn.btn-stake {
  height: 32px;
  font-size: .75rem
}

.v-btn.btn-stake {
  display: flex;
  justify-content: center;
  border-width: 1px;
  border-style: solid;
  box-shadow: none;
  border-radius: 30px;
  color: #fff;
  background: linear-gradient(128.38deg, #3431EA 0%, #6D39FF 100%);
  border-color: transparent
}

.v-btn.btn-stake.active {
  border-color: #ff5d5d;
  color: #ff5d5d
}

.v-btn.btn-stake.staked {
  background: transparent;
  color: #ff5d5d;
  border: 1px solid #FF5D5D
}

.v-btn.btn-stake.staked .v-btn__overlay {
  background: rgba(255, 93, 93, .2)
}

.v-btn.btn-stake.staked:hover>.v-btn__overlay {
  opacity: 1
}

.v-btn.btn-stake.unstaked .v-btn__overlay {
  background: linear-gradient(0deg, rgba(0, 0, 0, .2), rgba(0, 0, 0, .2)), linear-gradient(128.38deg, #3431EA 0%, #6D39FF 100%)
}

.v-btn.btn-stake.unstaked .v-btn__content {
  z-index: 1
}

.v-btn.btn-stake.unstaked:hover>.v-btn__overlay {
  opacity: 1
}


.v-btn.btn-purple-outline {
  color: #9e9cff;
  border-radius: 30px;
  overflow: hidden;
  background: transparent;
  transition: all .3s ease-in-out
}

.v-btn.btn-purple-outline,
.v-btn.btn-purple-outline .v-btn__overlay,
.v-btn.btn-purple-outline .v-btn__underlay {
  border-radius: 30px
}

.v-btn.btn-purple-outline .v-btn__underlay {
  opacity: 1;
  z-index: 1;
  border: 1px solid #9E9CFF;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  margin: 1px
}

.v-btn.btn-purple-outline .v-btn__content {
  z-index: 3
}

.v-btn.btn-purple-outline .v-btn__overlay {
  background: linear-gradient(270deg, #4340fc, #36379b);
  opacity: 0;
  z-index: 2;
  background-color: transparent;
  border-radius: 30px;
  transition: opacity .3s ease-in-out
}



.v-btn.btn-purple-outline:hover .v-btn__overlay {
  opacity: 1
}

.v-btn.btn-purple-outline:active .v-btn__overlay {
  background: linear-gradient(90deg, #2b2b7c, #3633c8)
}

.v-btn__content span {
  vertical-align: middle;
  display: inline-block
}

.v-tabs {
  margin: 26px 0
}

.v-tabs .v-tab {
  --v-tabs-height: 34px;
  min-width: auto;
  font-size: .875rem;
  padding: 0 4px;
  margin: 0 15px
}

.v-tabs .v-tab:first-child {
  margin-left: 0
}

.v-card--variant-contained {
  box-shadow: none
}

.v-input .v-field.v-field--variant-solo {
  border-radius: 5px;
  box-shadow: none;
  background: #282d5d
}

.v-input .v-field.v-field--variant-solo .v-field__overlay {
  opacity: 1
}

.v-input .v-input__details {
  margin-bottom: 5px
}

.v-input.hide-input-details .v-input__details {
  display: none !important
}

.v-overlay-container .v-select__content,
.v-overlay-container .v-combobox__content {
  max-height: 250px !important
}

.v-overlay-container .v-overlay--active {
  z-index: 98 !important
}

.v-overlay-container .v-overlay--absolute.v-overlay--active {
  z-index: 100 !important
}

.v-overlay-container .v-overlay--active:nth-of-type(3) {
  z-index: 0 !important
}

.v-overlay__content {
  overflow: auto
}

.v-overlay__scrim {
  opacity: .8;
  background: #000000
}

.v-slide-group {
  position: relative
}

.v-slide-group__prev,
.v-slide-group__next {
  position: absolute;
  z-index: 2;
  top: 50%;
  margin-top: calc(var(--button-size) * -.5);
  width: var(--button-size);
  height: var(--button-size);
  min-width: auto;
  flex-basis: var(--button-size);
  transition: transform .3s cubic-bezier(0, 0, 0, 1)
}

.v-slide-group__prev--disabled,
.v-slide-group__next--disabled {
  display: none
}

.v-slide-group__prev {
  left: calc(var(--button-size) * -1)
}

@media (min-width: 960px) {
  .v-slide-group__prev {
    left: calc(var(--button-size) * -2)
  }
}

.v-slide-group__prev:hover {
  transform: translate(-5px)
}

.v-slide-group__next {
  right: calc(var(--button-size) * -1)
}

@media (min-width: 960px) {
  .v-slide-group__next {
    right: calc(var(--button-size) * -2)
  }
}

.v-slide-group__next:hover {
  transform: translate(5px)
}

@media (min-width: 960px) and (max-width: 1279.98px) {
  body .v-container {
    max-width: 860px
  }
}

@media (min-width: 1280px) and (max-width: 1919.98px) {
  body .v-container {
    max-width: 1150px
  }
}

@media (min-width: 1920px) and (max-width: 2559.98px) {
  body .v-container {
    max-width: 1450px
  }
}

@media (min-width: 2560px) {
  body .v-container {
    max-width: 1710px
  }
}

.visuallyhidden,
.vh {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0
}

html {
  width: 100%
}

html.no-scroll body {
  height: 100%;
  overflow: hidden
}

.main.is-unconnected {
  width: 100%;
  height: calc(var(--vh, 1vh) * 100) !important;
  margin-left: 0;
  display: flex;
}
.bored-lines {
  border-radius: 8px;
}

.centered-dib {
  justify-content: center;
  align-items: center;
}

.main.is-open-to-public {
  height: auto;
}

.v-application.has-sidebar .main {
  padding: 10px;
  position: relative;
  z-index: 2
}

@media (max-width: 959.98px) {
  .v-application.has-sidebar .main {
    padding-top: 80px
  }
}

@media (min-width: 960px) {
  .v-application.has-sidebar .main {
    padding: 10px 30px;
    width: calc(100% - var(--sidebar-width) - var(--sidebar-margin));
    margin-left: calc(var(--sidebar-width) + var(--sidebar-margin))
  }
}

html,
body,
.v-application {
  font-family: filson-soft, sans-serif
}

body .v-application {}

ul {
  list-style: none
}

.text--title {
  font-family: filson-soft, sans-serif
}

.font-weight-semi-bold {
  font-weight: 600 !important
}

.text--warning {
  color: #ffd747
}

.text--success {
  color: #20ce50
}

.text--grey {
  color: #757b8c
}

.text--card-grey {
  color: #a6abba
}

ul.list-disc {
  list-style: disc
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  transition: background-color .3s ease-in-out;
  background-color: #757b8c
}



:root {
  --onboard-white: #241d32;
  --onboard-modal-color: #cfd4e1;
  --onboard-gray-700: #cfd4e1;
  --onboard-gray-600: #b5bbc6;
  --onboard-gray-500: #e0e4ee;
  --onboard-gray-300: #cfd4e1;
  --onboard-primary-100: #49566c;
  --onboard-primary-400: #2d305f;
  --account-center-maximized-upper-background: #a6abba;
  --account-center-minimized-upper-background: #a6abba;
  --account-center-maximized-app-info-section: #2d305f;
  --account-center-minimized-app-info-section: #2d305f;
  --account-center-maximized-network-section: #cfd4e1;
  --account-center-maximized-network-selector-color: #cfd4e1;
  --account-center-minimized-address-color: #e0e4ee;
  --account-center-maximized-address-color: #e0e4ee;
  --account-center-z-index: 99;
  --onboard-connect-content-width: 552px;
  --onboard-connect-content-height: 410px;
  --onboard-connect-header-color: white;
  --onboard-wallet-button-background: rgba(27, 22, 36, .65) !important;
  --onboard-wallet-button-background-hover: transparent !important;
  --onboard-wallet-button-color: white !important;
  --onboard-wallet-button-border-color: #49566c;
  --onboard-wallet-button-border-radius: 7px;
  --onboard-wallet-app-icon-border-color: transparent;
  --gutter-width: 15px;
  --button-size: 24px;
  --io-blue-main: #4340FC;
  --io-blue-light-1: #6966FF;
  --io-btn-blue: #3431EA;
  --io-btn-purple: #6D39FF;
  --io-staked-card: #272746;
  --neutral-grey-4: #E3E3E3;
  --neutral-blue-grey-5: #A6ABBA;
  --card-staked-bg: #2D2D57;
  --white: #ffffff
}

.carousel {
  --vc-pgn-width: 6px;
  --vc-pgn-height: 6px;
  --vc-pgn-margin: 12px;
  --vc-pgn-background-color: #CFD4E1;
  --vc-pgn-active-color: #9E9CFF
}

.walletconnect-modal__mobile__toggle a {
  color: #3c425299 !important
}

.v-alert {
  top: 30px;
  left: 50%;
  transform: translate(-50%);
  width: auto;
  min-width: 50%;
  z-index: 2401
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity .3s cubic-bezier(0, 0, 0, 1)
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0
}

.slowfade-enter-active,
.slowfade-leave-active {
  transition: opacity .6s cubic-bezier(0, 0, 0, 1)
}

.slowfade-enter-from,
.slowfade-leave-to {
  opacity: 0
}

.fadeout-leave-active {
  transition: opacity .6s cubic-bezier(0, 0, 0, 1)
}

.fadeout-leave-to {
  opacity: 0
}

.slide-left-enter-active,
.slide-left-leave-active {
  transition: opacity .5s cubic-bezier(0, 0, 0, 1), transform .5s cubic-bezier(0, 0, 0, 1)
}

.slide-left-enter-from,
.slide-left-leave-to {
  opacity: 0;
  transform: translate(100vw)
}

.t--white {
  color: #fff
}


.v-alert {
  display: grid;
  flex: 1 1;
  grid-template-areas: "prepend content append close"". content . .";
  grid-template-columns: max-content auto max-content max-content;
  position: relative;
  padding: 16px;
  overflow: hidden;
  --v-border-color: currentColor;
  border-radius: 4px
}

.v-alert--absolute {
  position: absolute
}

.v-alert--fixed {
  position: fixed
}

.v-alert--sticky {
  position: sticky
}

.v-alert--variant-plain,
.v-alert--variant-outlined,
.v-alert--variant-text,
.v-alert--variant-tonal {
  background: transparent;
  color: inherit
}

.v-alert--variant-plain {
  opacity: .62
}

.v-alert--variant-plain:focus,
.v-alert--variant-plain:hover {
  opacity: 1
}

.v-alert--variant-plain .v-alert__overlay {
  display: none
}

.v-alert--variant-elevated,
.v-alert--variant-flat {
  background: rgb(var(--v-theme-on-surface-variant));
  color: rgba(var(--v-theme-on-surface), var(--v-high-emphasis-opacity))
}

.v-alert--variant-elevated {
  box-shadow: 0 2px 1px -1px #0003, 0 1px 1px #00000024, 0 1px 3px #0000001f
}

.v-alert--variant-flat {
  box-shadow: 0 0 #0003, 0 0 #00000024, 0 0 #0000001f
}

.v-alert--variant-outlined {
  border: thin solid currentColor
}

.v-alert--variant-text .v-alert__overlay {
  background: currentColor
}

.v-alert--variant-tonal .v-alert__underlay {
  background: currentColor;
  opacity: var(--v-activated-opacity);
  border-radius: inherit;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  transition: opacity .2s ease-in-out
}

.v-alert--prominent {
  grid-template-areas: "prepend content append close""prepend content . ."
}

.v-alert.v-alert--border {
  --v-border-opacity: .38
}

.v-alert.v-alert--border.v-alert--border-start {
  padding-inline-start: 24px
}

.v-alert.v-alert--border.v-alert--border-end {
  padding-inline-end: 24px
}

.v-alert--variant-plain {
  transition: .2s opacity cubic-bezier(.4, 0, .2, 1)
}

.v-alert--density-default {
  padding-bottom: 16px;
  padding-top: 16px
}

.v-alert--density-default.v-alert--border-top {
  padding-top: 24px
}

.v-alert--density-default.v-alert--border-bottom {
  padding-bottom: 24px
}

.v-alert--density-comfortable {
  padding-bottom: 12px;
  padding-top: 12px
}

.v-alert--density-comfortable.v-alert--border-top {
  padding-top: 20px
}

.v-alert--density-comfortable.v-alert--border-bottom {
  padding-bottom: 20px
}

.v-alert--density-compact {
  padding-bottom: 8px;
  padding-top: 8px
}

.v-alert--density-compact.v-alert--border-top {
  padding-top: 16px
}

.v-alert--density-compact.v-alert--border-bottom {
  padding-bottom: 16px
}

.v-alert__border {
  border-radius: inherit;
  bottom: 0;
  left: 0;
  opacity: var(--v-border-opacity);
  position: absolute;
  pointer-events: none;
  right: 0;
  top: 0;
  width: 100%;
  border-color: currentColor;
  border-style: solid;
  border-width: 0
}

.v-alert__border--border {
  border-width: 8px;
  box-shadow: none
}

.v-alert--border-start .v-alert__border {
  border-inline-start-width: 8px
}

.v-alert--border-end .v-alert__border {
  border-inline-end-width: 8px
}

.v-alert--border-top .v-alert__border {
  border-top-width: 8px
}

.v-alert--border-bottom .v-alert__border {
  border-bottom-width: 8px
}

.v-alert__close {
  cursor: pointer;
  display: flex;
  flex: 0 1 auto;
  grid-area: close;
  user-select: none
}

.v-alert__content {
  align-self: center;
  grid-area: content;
  overflow: hidden
}

.v-alert__append,
.v-alert__close {
  align-self: flex-start;
  margin-inline-start: 90px
}

.v-alert__append {
  align-self: flex-start;
  grid-area: append
}

.v-alert__append+.v-alert__close {
  margin-inline-start: 16px
}

.v-alert__prepend {
  align-self: flex-start;
  display: flex;
  align-items: center;
  grid-area: prepend;
  margin-inline-end: 16px
}

.v-alert--prominent .v-alert__prepend {
  align-self: center
}

.v-alert__underlay {
  grid-area: none;
  position: absolute
}

.v-alert--border-start .v-alert__underlay {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0
}

.v-alert--border-end .v-alert__underlay {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0
}

.v-alert--border-top .v-alert__underlay {
  border-top-left-radius: 0;
  border-top-right-radius: 0
}

.v-alert--border-bottom .v-alert__underlay {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0
}

.v-alert-title {
  align-items: center;
  align-self: center;
  display: flex;
  font-size: 1.25rem;
  font-weight: 500;
  hyphens: auto;
  letter-spacing: .0125em;
  line-height: 1.75rem;
  overflow-wrap: normal;
  text-transform: none;
  word-break: normal;
  word-wrap: break-word
}


.v-application__wrap {
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  max-width: 100%;
  min-height: 100vh;
  position: relative
}


.base-full-page-loader {
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  position: fixed;
  z-index: 101;
  background: #141518;
  display: flex;
  justify-items: center;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
}

.v-main__wrap {
  flex: 1 1 auto;
  max-width: 100%;
  position: relative
}

.v-application.is-landing-page .main:before {
  content: "";
  display: block;
  position: absolute;
  top: 270vh;
  left: 0;
  transform: translate(-40%);
  background: url(/public/images/glow-2.bb3da2bd.png);
  background-size: contain;
  width: 180vh;
  height: 180vh;
}


.main.is-open-to-public {
  height: auto;
  overflow-x: hidden;
}
.main.is-unconnected {
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  margin-left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.v-main {
  display: flex;
  flex: 1 0 auto;
  max-width: 100%;
  position: relative;
  padding: 0px;
  transition: .2s cubic-bezier(.4,0,.2,1);
}

@-moz-document url-prefix() {
  @media print {
    .v-main {
      display: block
    }
  }
}

*,
:before,
:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e5e7eb
}

* {
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, .5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000
}

:root {
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4
}

:-moz-focusring {
  outline: 1px dotted ButtonText
}

:-moz-ui-invalid {
  box-shadow: none
}

::moz-focus-inner {
  border-style: none;
  padding: 0
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto
}

::-webkit-search-decoration {
  -webkit-appearance: none
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px
}

abbr[title] {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted
}

a {
  color: inherit;
  text-decoration: inherit
}

body {
  margin: 0;
  font-family: inherit;
  color: white;
  line-height: inherit
}

button {
  text-transform: none;
  background-color: transparent;
  background-image: none
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button
}

button,
[role=button] {
  cursor: pointer
}

fieldset {
  margin: 0;
  padding: 0
}

html {
  -webkit-text-size-adjust: 100%;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", Segoe UI Symbol, "Noto Color Emoji";
  line-height: 1.5
}

h1,
h3,
h4,
h2 {
  font-size: inherit;
  font-weight: inherit
}

input,
button {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  padding: 0;
  line-height: inherit;
  color: inherit
}

img {
  border-style: solid
}

input::placeholder {
  opacity: 1;
  color: #9ca3af
}

input::webkit-input-placeholder {
  opacity: 1;
  color: #9ca3af
}

input::-moz-placeholder {
  opacity: 1;
  color: #9ca3af
}

input:-ms-input-placeholder {
  opacity: 1;
  color: #9ca3af
}

input::-ms-input-placeholder {
  opacity: 1;
  color: #9ca3af
}

p,
h1,
h3,
h4,
h2 {
  margin: 0
}

strong {
  font-weight: bolder
}

small {
  font-size: 80%
}

svg,
video,
img {
  display: block;
  vertical-align: middle
}

ul {
  list-style: none;
  margin: 0;
  padding: 0
}

video,
img {
  max-width: 100%;
  height: auto
}

.container {
  width: 100%
}

@media (min-width: 640px) {
  .container {
    max-width: 640px
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px
  }
}

.space-x-4>:not([hidden])~:not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-x-10>:not([hidden])~:not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2.5rem * var(--tw-space-x-reverse));
  margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)))
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity))
}

.rounded {
  border-radius: .25rem
}

.rounded-2xl {
  border-radius: 1rem
}

.rounded-3xl {
  border-radius: 1.5rem
}

.border-solid {
  border-style: solid
}

.border {
  border-width: 1px
}

.block {
  display: block
}

.inline-block {
  display: inline-block
}

.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex
}

.table {
  display: table
}

.contents {
  display: contents
}

.hidden {
  display: none
}

.flex-row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row
}

.flex-wrap {
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap
}

.items-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center
}

.justify-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end
}

.justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center
}

.justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between
}

.flex-auto {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto
}

.flex-grow-0 {
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  -webkit-flex-grow: 0;
  flex-grow: 0
}

.font-weight-bold,
.font-weight-semi-bold {
  font-weight: 700
}

.font-weight-medium {
  font-weight: 500
}

.font-bold {
  font-weight: 700
}

.h-10 {
  height: 2.5rem
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem
}

.text-9xl {
  font-size: 8rem;
  line-height: 1
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem
}

.list-disc {
  list-style-type: disc
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem
}

.my-3 {
  margin-top: .75rem;
  margin-bottom: .75rem
}

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem
}

.mt-5 {
  margin-top: 1.25rem
}

.mt-3 {
  margin-top: .75rem
}

.mt-4 {
  margin-top: 1rem
}

.mt-2 {
  margin-top: .5rem
}

.mt-6 {
  margin-top: 1.5rem
}

.mt-8 {
  margin-top: 2rem
}

.ml-4 {
  margin-left: 1rem
}

.mr-4 {
  margin-right: 1rem
}

.mb-10 {
  margin-bottom: 2.5rem
}

.mt-10 {
  margin-top: 2.5rem
}

.ml-1 {
  margin-left: .25rem
}

.mr-1 {
  margin-right: .25rem
}

.ml-3 {
  margin-left: .75rem
}

.mr-3 {
  margin-right: .75rem
}

.mb-3 {
  margin-bottom: .75rem
}

.mt-1 {
  margin-top: .25rem
}

.mb-4 {
  margin-bottom: 1rem
}

.mb-5 {
  margin-bottom: 1.25rem
}

.ml-2 {
  margin-left: .5rem
}

.mb-2 {
  margin-bottom: .5rem
}

.mb-1 {
  margin-bottom: .25rem
}

.min-w-10 {
  min-width: 2.5rem
}

.p-4 {
  padding: 1rem
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0
}

.pb-0 {
  padding-bottom: 0
}

.fixed {
  position: fixed
}

.resize {
  resize: both
}

.text-center {
  text-align: center
}

.text-gray-900,
.hover\:text-gray-900:hover {
  --tw-text-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-text-opacity))
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity))
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity))
}

.content-wrapper {
  content: "wrapper"
}

.visible {
  visibility: visible
}

.w-full {
  width: 100%
}

.transform {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-translate-z: 0;
  --tw-rotate: 0;
  --tw-rotate-x: 0;
  --tw-rotate-y: 0;
  --tw-rotate-z: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-scale-z: 1;
  -webkit-transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) translateZ(var(--tw-translate-z)) rotate(var(--tw-rotate)) rotateX(var(--tw-rotate-x)) rotateY(var(--tw-rotate-y)) rotateZ(var(--tw-rotate-z)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) scaleZ(var(--tw-scale-z));
  -ms-transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) translateZ(var(--tw-translate-z)) rotate(var(--tw-rotate)) rotateX(var(--tw-rotate-x)) rotateY(var(--tw-rotate-y)) rotateZ(var(--tw-rotate-z)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) scaleZ(var(--tw-scale-z));
  transform: translate(var(--tw-translate-x)) translateY(var(--tw-translate-y)) translateZ(var(--tw-translate-z)) rotate(var(--tw-rotate)) rotateX(var(--tw-rotate-x)) rotateY(var(--tw-rotate-y)) rotate(var(--tw-rotate-z)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) scaleZ(var(--tw-scale-z))
}

.ease,
.ease-in-out {
  -webkit-transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  -o-transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  transition-timing-function: cubic-bezier(.4, 0, .2, 1)
}

.\.duration,
.duration {
  -webkit-transition-duration: .15s;
  -o-transition-duration: .15s;
  transition-duration: .15s
}

.animate {
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1
}

.filter {
  --tw-blur: var(--tw-empty, );
  --tw-brightness: var(--tw-empty, );
  --tw-contrast: var(--tw-empty, );
  --tw-grayscale: var(--tw-empty, );
  --tw-hue-rotate: var(--tw-empty, );
  --tw-invert: var(--tw-empty, );
  --tw-saturate: var(--tw-empty, );
  --tw-sepia: var(--tw-empty, );
  --tw-drop-shadow: var(--tw-empty, );
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

@media (min-width: 640px) {
  .sm\:flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex
  }

  .sm\:hidden {
    display: none
  }
}

:root {
  --vc-clr-primary: #642afb;
  --vc-clr-secondary: #8e98f3;
  --vc-clr-white: #ffffff;
  --vc-icn-width: 1.2em;
  --vc-nav-width: 30px;
  --vc-nav-color: #ffffff;
  --vc-nav-background-color: var(--vc-clr-primary);
  --vc-pgn-width: 10px;
  --vc-pgn-height: 5px;
  --vc-pgn-margin: 5px;
  --vc-pgn-border-radius: 0;
  --vc-pgn-background-color: var(--vc-clr-secondary);
  --vc-pgn-active-color: var(--vc-clr-primary)
}

.carousel__prev,
.carousel__next {
  background-color: var(--vc-nav-background-color);
  border-radius: var(--vc-nav-width);
  width: var(--vc-nav-width);
  height: var(--vc-nav-width);
  text-align: center;
  font-size: calc(var(--vc-nav-width) * 2 / 3);
  padding: 0;
  color: var(--vc-nav-color);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  border: 0;
  cursor: pointer
}

.carousel__prev {
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%)
}

.carousel__next {
  top: 50%;
  right: 0;
  transform: translate(50%, -50%)
}

.carousel--rtl .carousel__prev {
  left: auto;
  right: 0;
  transform: translate(50%, -50%)
}

.carousel--rtl .carousel__next {
  right: auto;
  left: 0;
  transform: translate(-50%, -50%)
}

.carousel__pagination {
  display: flex;
  justify-content: center;
  list-style: none
}

.carousel__pagination-button {
  margin: var(--vc-pgn-margin);
  width: var(--vc-pgn-width);
  height: var(--vc-pgn-height);
  border-radius: var(--vc-pgn-height);
  border: 0;
  cursor: pointer;
  background-color: var(--vc-pgn-background-color)
}

.carousel__pagination-button--active {
  background-color: var(--vc-pgn-active-color)
}

.carousel__slide {
  scroll-snap-stop: auto;
  flex-shrink: 0;
  margin: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center
}

.carousel {
  position: relative;
  text-align: center;
  box-sizing: border-box
}

.carousel * {
  box-sizing: border-box
}

.carousel__track {
  display: flex;
  margin: 0;
  padding: 0;
  position: relative
}

.carousel__viewport {
  overflow: hidden
}

.carousel__icon {
  width: var(--vc-icn-width);
  height: var(--vc-icn-width);
  fill: currentColor
}

.btn {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  border-radius: .25rem;
  cursor: pointer;
  display: inline-block;
  padding: .75rem 1.5rem;
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity))
}

.btn:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity))
}

.btn:disabled {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  cursor: default;
  opacity: .7
}

.btn:focus {
  outline: 2px solid transparent;
  outline-offset: 2px
}

@keyframes v-shake {
  59% {
    margin-left: 0
  }

  60%,
  80% {
    margin-left: 2px
  }

  70%,
  90% {
    margin-left: -2px
  }
}

.bg-black {
  background-color: #000 !important;
  color: #fff !important
}

.text-black {
  color: #000 !important;
  caret-color: #000 !important
}

.bg-white {
  background-color: #fff !important;
  color: #000 !important
}

.text-white {
  color: #fff !important;
  caret-color: #fff !important
}

.bg-transparent {
  background-color: transparent !important;
  color: currentColor !important
}

.text-transparent {
  color: transparent !important;
  caret-color: transparent !important
}

.bg-red {
  background-color: #f44336 !important;
  color: #000 !important
}

.text-red {
  color: #f44336 !important;
  caret-color: #f44336 !important
}

.bg-red-lighten-5 {
  background-color: #ffebee !important;
  color: #000 !important
}

.text-red-lighten-5 {
  color: #ffebee !important;
  caret-color: #ffebee !important
}

.bg-red-lighten-4 {
  background-color: #ffcdd2 !important;
  color: #000 !important
}

.text-red-lighten-4 {
  color: #ffcdd2 !important;
  caret-color: #ffcdd2 !important
}

.bg-red-lighten-3 {
  background-color: #ef9a9a !important;
  color: #000 !important
}

.text-red-lighten-3 {
  color: #ef9a9a !important;
  caret-color: #ef9a9a !important
}

.bg-red-lighten-2 {
  background-color: #e57373 !important;
  color: #000 !important
}

.text-red-lighten-2 {
  color: #e57373 !important;
  caret-color: #e57373 !important
}

.bg-red-lighten-1 {
  background-color: #ef5350 !important;
  color: #000 !important
}

.text-red-lighten-1 {
  color: #ef5350 !important;
  caret-color: #ef5350 !important
}

.bg-red-darken-1 {
  background-color: #e53935 !important;
  color: #000 !important
}

.text-red-darken-1 {
  color: #e53935 !important;
  caret-color: #e53935 !important
}

.bg-red-darken-2 {
  background-color: #d32f2f !important;
  color: #fff !important
}

.text-red-darken-2 {
  color: #d32f2f !important;
  caret-color: #d32f2f !important
}

.bg-red-darken-3 {
  background-color: #c62828 !important;
  color: #fff !important
}

.text-red-darken-3 {
  color: #c62828 !important;
  caret-color: #c62828 !important
}

.bg-red-darken-4 {
  background-color: #b71c1c !important;
  color: #fff !important
}

.text-red-darken-4 {
  color: #b71c1c !important;
  caret-color: #b71c1c !important
}

.bg-red-accent-1 {
  background-color: #ff8a80 !important;
  color: #000 !important
}

.text-red-accent-1 {
  color: #ff8a80 !important;
  caret-color: #ff8a80 !important
}

.bg-red-accent-2 {
  background-color: #ff5252 !important;
  color: #000 !important
}

.text-red-accent-2 {
  color: #ff5252 !important;
  caret-color: #ff5252 !important
}

.bg-red-accent-3 {
  background-color: #ff1744 !important;
  color: #000 !important
}

.text-red-accent-3 {
  color: #ff1744 !important;
  caret-color: #ff1744 !important
}

.bg-red-accent-4 {
  background-color: #d50000 !important;
  color: #fff !important
}

.text-red-accent-4 {
  color: #d50000 !important;
  caret-color: #d50000 !important
}

.bg-pink {
  background-color: #e91e63 !important;
  color: #000 !important
}

.text-pink {
  color: #e91e63 !important;
  caret-color: #e91e63 !important
}

.bg-pink-lighten-5 {
  background-color: #fce4ec !important;
  color: #000 !important
}

.text-pink-lighten-5 {
  color: #fce4ec !important;
  caret-color: #fce4ec !important
}

.bg-pink-lighten-4 {
  background-color: #f8bbd0 !important;
  color: #000 !important
}

.text-pink-lighten-4 {
  color: #f8bbd0 !important;
  caret-color: #f8bbd0 !important
}

.bg-pink-lighten-3 {
  background-color: #f48fb1 !important;
  color: #000 !important
}

.text-pink-lighten-3 {
  color: #f48fb1 !important;
  caret-color: #f48fb1 !important
}

.bg-pink-lighten-2 {
  background-color: #f06292 !important;
  color: #000 !important
}

.text-pink-lighten-2 {
  color: #f06292 !important;
  caret-color: #f06292 !important
}

.bg-pink-lighten-1 {
  background-color: #ec407a !important;
  color: #000 !important
}

.text-pink-lighten-1 {
  color: #ec407a !important;
  caret-color: #ec407a !important
}

.bg-pink-darken-1 {
  background-color: #d81b60 !important;
  color: #fff !important
}

.text-pink-darken-1 {
  color: #d81b60 !important;
  caret-color: #d81b60 !important
}

.bg-pink-darken-2 {
  background-color: #c2185b !important;
  color: #fff !important
}

.text-pink-darken-2 {
  color: #c2185b !important;
  caret-color: #c2185b !important
}

.bg-pink-darken-3 {
  background-color: #ad1457 !important;
  color: #fff !important
}

.text-pink-darken-3 {
  color: #ad1457 !important;
  caret-color: #ad1457 !important
}

.bg-pink-darken-4 {
  background-color: #880e4f !important;
  color: #fff !important
}

.text-pink-darken-4 {
  color: #880e4f !important;
  caret-color: #880e4f !important
}

.bg-pink-accent-1 {
  background-color: #ff80ab !important;
  color: #000 !important
}

.text-pink-accent-1 {
  color: #ff80ab !important;
  caret-color: #ff80ab !important
}

.bg-pink-accent-2 {
  background-color: #ff4081 !important;
  color: #000 !important
}

.text-pink-accent-2 {
  color: #ff4081 !important;
  caret-color: #ff4081 !important
}

.bg-pink-accent-3 {
  background-color: #f50057 !important;
  color: #000 !important
}

.text-pink-accent-3 {
  color: #f50057 !important;
  caret-color: #f50057 !important
}

.bg-pink-accent-4 {
  background-color: #c51162 !important;
  color: #fff !important
}

.text-pink-accent-4 {
  color: #c51162 !important;
  caret-color: #c51162 !important
}

.bg-purple {
  background-color: #9c27b0 !important;
  color: #fff !important
}

.text-purple {
  color: #9c27b0 !important;
  caret-color: #9c27b0 !important
}

.bg-purple-lighten-5 {
  background-color: #f3e5f5 !important;
  color: #000 !important
}

.text-purple-lighten-5 {
  color: #f3e5f5 !important;
  caret-color: #f3e5f5 !important
}

.bg-purple-lighten-4 {
  background-color: #e1bee7 !important;
  color: #000 !important
}

.text-purple-lighten-4 {
  color: #e1bee7 !important;
  caret-color: #e1bee7 !important
}

.bg-purple-lighten-3 {
  background-color: #ce93d8 !important;
  color: #000 !important
}

.text-purple-lighten-3 {
  color: #ce93d8 !important;
  caret-color: #ce93d8 !important
}

.bg-purple-lighten-2 {
  background-color: #ba68c8 !important;
  color: #000 !important
}

.text-purple-lighten-2 {
  color: #ba68c8 !important;
  caret-color: #ba68c8 !important
}

.bg-purple-lighten-1 {
  background-color: #ab47bc !important;
  color: #fff !important
}

.text-purple-lighten-1 {
  color: #ab47bc !important;
  caret-color: #ab47bc !important
}

.bg-purple-darken-1 {
  background-color: #8e24aa !important;
  color: #fff !important
}

.text-purple-darken-1 {
  color: #8e24aa !important;
  caret-color: #8e24aa !important
}

.bg-purple-darken-2 {
  background-color: #7b1fa2 !important;
  color: #fff !important
}

.text-purple-darken-2 {
  color: #7b1fa2 !important;
  caret-color: #7b1fa2 !important
}

.bg-purple-darken-3 {
  background-color: #6a1b9a !important;
  color: #fff !important
}

.text-purple-darken-3 {
  color: #6a1b9a !important;
  caret-color: #6a1b9a !important
}

.bg-purple-darken-4 {
  background-color: #4a148c !important;
  color: #fff !important
}

.text-purple-darken-4 {
  color: #4a148c !important;
  caret-color: #4a148c !important
}

.bg-purple-accent-1 {
  background-color: #ea80fc !important;
  color: #000 !important
}

.text-purple-accent-1 {
  color: #ea80fc !important;
  caret-color: #ea80fc !important
}

.bg-purple-accent-2 {
  background-color: #e040fb !important;
  color: #000 !important
}

.text-purple-accent-2 {
  color: #e040fb !important;
  caret-color: #e040fb !important
}

.bg-purple-accent-3 {
  background-color: #d500f9 !important;
  color: #000 !important
}

.text-purple-accent-3 {
  color: #d500f9 !important;
  caret-color: #d500f9 !important
}

.bg-purple-accent-4 {
  background-color: #a0f !important;
  color: #fff !important
}

.text-purple-accent-4 {
  color: #a0f !important;
  caret-color: #a0f !important
}

.bg-deep-purple {
  background-color: #673ab7 !important;
  color: #fff !important
}

.text-deep-purple {
  color: #673ab7 !important;
  caret-color: #673ab7 !important
}

.bg-deep-purple-lighten-5 {
  background-color: #ede7f6 !important;
  color: #000 !important
}

.text-deep-purple-lighten-5 {
  color: #ede7f6 !important;
  caret-color: #ede7f6 !important
}

.bg-deep-purple-lighten-4 {
  background-color: #d1c4e9 !important;
  color: #000 !important
}

.text-deep-purple-lighten-4 {
  color: #d1c4e9 !important;
  caret-color: #d1c4e9 !important
}

.bg-deep-purple-lighten-3 {
  background-color: #b39ddb !important;
  color: #000 !important
}

.text-deep-purple-lighten-3 {
  color: #b39ddb !important;
  caret-color: #b39ddb !important
}

.bg-deep-purple-lighten-2 {
  background-color: #9575cd !important;
  color: #000 !important
}

.text-deep-purple-lighten-2 {
  color: #9575cd !important;
  caret-color: #9575cd !important
}

.bg-deep-purple-lighten-1 {
  background-color: #7e57c2 !important;
  color: #fff !important
}

.text-deep-purple-lighten-1 {
  color: #7e57c2 !important;
  caret-color: #7e57c2 !important
}

.bg-deep-purple-darken-1 {
  background-color: #5e35b1 !important;
  color: #fff !important
}

.text-deep-purple-darken-1 {
  color: #5e35b1 !important;
  caret-color: #5e35b1 !important
}

.bg-deep-purple-darken-2 {
  background-color: #512da8 !important;
  color: #fff !important
}

.text-deep-purple-darken-2 {
  color: #512da8 !important;
  caret-color: #512da8 !important
}

.bg-deep-purple-darken-3 {
  background-color: #4527a0 !important;
  color: #fff !important
}

.text-deep-purple-darken-3 {
  color: #4527a0 !important;
  caret-color: #4527a0 !important
}

.bg-deep-purple-darken-4 {
  background-color: #311b92 !important;
  color: #fff !important
}

.text-deep-purple-darken-4 {
  color: #311b92 !important;
  caret-color: #311b92 !important
}

.bg-deep-purple-accent-1 {
  background-color: #b388ff !important;
  color: #000 !important
}

.text-deep-purple-accent-1 {
  color: #b388ff !important;
  caret-color: #b388ff !important
}

.bg-deep-purple-accent-2 {
  background-color: #7c4dff !important;
  color: #fff !important
}

.text-deep-purple-accent-2 {
  color: #7c4dff !important;
  caret-color: #7c4dff !important
}

.bg-deep-purple-accent-3 {
  background-color: #651fff !important;
  color: #fff !important
}

.text-deep-purple-accent-3 {
  color: #651fff !important;
  caret-color: #651fff !important
}

.bg-deep-purple-accent-4 {
  background-color: #6200ea !important;
  color: #fff !important
}

.text-deep-purple-accent-4 {
  color: #6200ea !important;
  caret-color: #6200ea !important
}

.bg-indigo {
  background-color: #3f51b5 !important;
  color: #fff !important
}

.text-indigo {
  color: #3f51b5 !important;
  caret-color: #3f51b5 !important
}

.bg-indigo-lighten-5 {
  background-color: #e8eaf6 !important;
  color: #000 !important
}

.text-indigo-lighten-5 {
  color: #e8eaf6 !important;
  caret-color: #e8eaf6 !important
}

.bg-indigo-lighten-4 {
  background-color: #c5cae9 !important;
  color: #000 !important
}

.text-indigo-lighten-4 {
  color: #c5cae9 !important;
  caret-color: #c5cae9 !important
}

.bg-indigo-lighten-3 {
  background-color: #9fa8da !important;
  color: #000 !important
}

.text-indigo-lighten-3 {
  color: #9fa8da !important;
  caret-color: #9fa8da !important
}

.bg-indigo-lighten-2 {
  background-color: #7986cb !important;
  color: #000 !important
}

.text-indigo-lighten-2 {
  color: #7986cb !important;
  caret-color: #7986cb !important
}

.bg-indigo-lighten-1 {
  background-color: #5c6bc0 !important;
  color: #fff !important
}

.text-indigo-lighten-1 {
  color: #5c6bc0 !important;
  caret-color: #5c6bc0 !important
}

.bg-indigo-darken-1 {
  background-color: #3949ab !important;
  color: #fff !important
}

.text-indigo-darken-1 {
  color: #3949ab !important;
  caret-color: #3949ab !important
}

.bg-indigo-darken-2 {
  background-color: #303f9f !important;
  color: #fff !important
}

.text-indigo-darken-2 {
  color: #303f9f !important;
  caret-color: #303f9f !important
}

.bg-indigo-darken-3 {
  background-color: #283593 !important;
  color: #fff !important
}

.text-indigo-darken-3 {
  color: #283593 !important;
  caret-color: #283593 !important
}

.bg-indigo-darken-4 {
  background-color: #1a237e !important;
  color: #fff !important
}

.text-indigo-darken-4 {
  color: #1a237e !important;
  caret-color: #1a237e !important
}

.bg-indigo-accent-1 {
  background-color: #8c9eff !important;
  color: #000 !important
}

.text-indigo-accent-1 {
  color: #8c9eff !important;
  caret-color: #8c9eff !important
}

.bg-indigo-accent-2 {
  background-color: #536dfe !important;
  color: #000 !important
}

.text-indigo-accent-2 {
  color: #536dfe !important;
  caret-color: #536dfe !important
}

.bg-indigo-accent-3 {
  background-color: #3d5afe !important;
  color: #fff !important
}

.text-indigo-accent-3 {
  color: #3d5afe !important;
  caret-color: #3d5afe !important
}

.bg-indigo-accent-4 {
  background-color: #304ffe !important;
  color: #fff !important
}

.text-indigo-accent-4 {
  color: #304ffe !important;
  caret-color: #304ffe !important
}

.bg-blue {
  background-color: #2196f3 !important;
  color: #000 !important
}

.text-blue {
  color: #2196f3 !important;
  caret-color: #2196f3 !important
}

.bg-blue-lighten-5 {
  background-color: #e3f2fd !important;
  color: #000 !important
}

.text-blue-lighten-5 {
  color: #e3f2fd !important;
  caret-color: #e3f2fd !important
}

.bg-blue-lighten-4 {
  background-color: #bbdefb !important;
  color: #000 !important
}

.text-blue-lighten-4 {
  color: #bbdefb !important;
  caret-color: #bbdefb !important
}

.bg-blue-lighten-3 {
  background-color: #90caf9 !important;
  color: #000 !important
}

.text-blue-lighten-3 {
  color: #90caf9 !important;
  caret-color: #90caf9 !important
}

.bg-blue-lighten-2 {
  background-color: #64b5f6 !important;
  color: #000 !important
}

.text-blue-lighten-2 {
  color: #64b5f6 !important;
  caret-color: #64b5f6 !important
}

.bg-blue-lighten-1 {
  background-color: #42a5f5 !important;
  color: #000 !important
}

.text-blue-lighten-1 {
  color: #42a5f5 !important;
  caret-color: #42a5f5 !important
}

.bg-blue-darken-1 {
  background-color: #1e88e5 !important;
  color: #000 !important
}

.text-blue-darken-1 {
  color: #1e88e5 !important;
  caret-color: #1e88e5 !important
}

.bg-blue-darken-2 {
  background-color: #1976d2 !important;
  color: #fff !important
}

.text-blue-darken-2 {
  color: #1976d2 !important;
  caret-color: #1976d2 !important
}

.bg-blue-darken-3 {
  background-color: #1565c0 !important;
  color: #fff !important
}

.text-blue-darken-3 {
  color: #1565c0 !important;
  caret-color: #1565c0 !important
}

.bg-blue-darken-4 {
  background-color: #0d47a1 !important;
  color: #fff !important
}

.text-blue-darken-4 {
  color: #0d47a1 !important;
  caret-color: #0d47a1 !important
}

.bg-blue-accent-1 {
  background-color: #82b1ff !important;
  color: #000 !important
}

.text-blue-accent-1 {
  color: #82b1ff !important;
  caret-color: #82b1ff !important
}

.bg-blue-accent-2 {
  background-color: #448aff !important;
  color: #000 !important
}

.text-blue-accent-2 {
  color: #448aff !important;
  caret-color: #448aff !important
}

.bg-blue-accent-3 {
  background-color: #2979ff !important;
  color: #000 !important
}

.text-blue-accent-3 {
  color: #2979ff !important;
  caret-color: #2979ff !important
}

.bg-blue-accent-4 {
  background-color: #2962ff !important;
  color: #fff !important
}

.text-blue-accent-4 {
  color: #2962ff !important;
  caret-color: #2962ff !important
}

.bg-light-blue {
  background-color: #03a9f4 !important;
  color: #000 !important
}

.text-light-blue {
  color: #03a9f4 !important;
  caret-color: #03a9f4 !important
}

.bg-light-blue-lighten-5 {
  background-color: #e1f5fe !important;
  color: #000 !important
}

.text-light-blue-lighten-5 {
  color: #e1f5fe !important;
  caret-color: #e1f5fe !important
}

.bg-light-blue-lighten-4 {
  background-color: #b3e5fc !important;
  color: #000 !important
}

.text-light-blue-lighten-4 {
  color: #b3e5fc !important;
  caret-color: #b3e5fc !important
}

.bg-light-blue-lighten-3 {
  background-color: #81d4fa !important;
  color: #000 !important
}

.text-light-blue-lighten-3 {
  color: #81d4fa !important;
  caret-color: #81d4fa !important
}

.bg-light-blue-lighten-2 {
  background-color: #4fc3f7 !important;
  color: #000 !important
}

.text-light-blue-lighten-2 {
  color: #4fc3f7 !important;
  caret-color: #4fc3f7 !important
}

.bg-light-blue-lighten-1 {
  background-color: #29b6f6 !important;
  color: #000 !important
}

.text-light-blue-lighten-1 {
  color: #29b6f6 !important;
  caret-color: #29b6f6 !important
}

.bg-light-blue-darken-1 {
  background-color: #039be5 !important;
  color: #000 !important
}

.text-light-blue-darken-1 {
  color: #039be5 !important;
  caret-color: #039be5 !important
}

.bg-light-blue-darken-2 {
  background-color: #0288d1 !important;
  color: #000 !important
}

.text-light-blue-darken-2 {
  color: #0288d1 !important;
  caret-color: #0288d1 !important
}

.bg-light-blue-darken-3 {
  background-color: #0277bd !important;
  color: #fff !important
}

.text-light-blue-darken-3 {
  color: #0277bd !important;
  caret-color: #0277bd !important
}

.bg-light-blue-darken-4 {
  background-color: #01579b !important;
  color: #fff !important
}

.text-light-blue-darken-4 {
  color: #01579b !important;
  caret-color: #01579b !important
}

.bg-light-blue-accent-1 {
  background-color: #80d8ff !important;
  color: #000 !important
}

.text-light-blue-accent-1 {
  color: #80d8ff !important;
  caret-color: #80d8ff !important
}

.bg-light-blue-accent-2 {
  background-color: #40c4ff !important;
  color: #000 !important
}

.text-light-blue-accent-2 {
  color: #40c4ff !important;
  caret-color: #40c4ff !important
}

.bg-light-blue-accent-3 {
  background-color: #00b0ff !important;
  color: #000 !important
}

.text-light-blue-accent-3 {
  color: #00b0ff !important;
  caret-color: #00b0ff !important
}

.bg-light-blue-accent-4 {
  background-color: #0091ea !important;
  color: #000 !important
}

.text-light-blue-accent-4 {
  color: #0091ea !important;
  caret-color: #0091ea !important
}

.bg-cyan {
  background-color: #00bcd4 !important;
  color: #000 !important
}

.text-cyan {
  color: #00bcd4 !important;
  caret-color: #00bcd4 !important
}

.bg-cyan-lighten-5 {
  background-color: #e0f7fa !important;
  color: #000 !important
}

.text-cyan-lighten-5 {
  color: #e0f7fa !important;
  caret-color: #e0f7fa !important
}

.bg-cyan-lighten-4 {
  background-color: #b2ebf2 !important;
  color: #000 !important
}

.text-cyan-lighten-4 {
  color: #b2ebf2 !important;
  caret-color: #b2ebf2 !important
}

.bg-cyan-lighten-3 {
  background-color: #80deea !important;
  color: #000 !important
}

.text-cyan-lighten-3 {
  color: #80deea !important;
  caret-color: #80deea !important
}

.bg-cyan-lighten-2 {
  background-color: #4dd0e1 !important;
  color: #000 !important
}

.text-cyan-lighten-2 {
  color: #4dd0e1 !important;
  caret-color: #4dd0e1 !important
}

.bg-cyan-lighten-1 {
  background-color: #26c6da !important;
  color: #000 !important
}

.text-cyan-lighten-1 {
  color: #26c6da !important;
  caret-color: #26c6da !important
}

.bg-cyan-darken-1 {
  background-color: #00acc1 !important;
  color: #000 !important
}

.text-cyan-darken-1 {
  color: #00acc1 !important;
  caret-color: #00acc1 !important
}

.bg-cyan-darken-2 {
  background-color: #0097a7 !important;
  color: #000 !important
}

.text-cyan-darken-2 {
  color: #0097a7 !important;
  caret-color: #0097a7 !important
}

.bg-cyan-darken-3 {
  background-color: #00838f !important;
  color: #000 !important
}

.text-cyan-darken-3 {
  color: #00838f !important;
  caret-color: #00838f !important
}

.bg-cyan-darken-4 {
  background-color: #006064 !important;
  color: #fff !important
}

.text-cyan-darken-4 {
  color: #006064 !important;
  caret-color: #006064 !important
}

.bg-cyan-accent-1 {
  background-color: #84ffff !important;
  color: #000 !important
}

.text-cyan-accent-1 {
  color: #84ffff !important;
  caret-color: #84ffff !important
}

.bg-cyan-accent-2 {
  background-color: #18ffff !important;
  color: #000 !important
}

.text-cyan-accent-2 {
  color: #18ffff !important;
  caret-color: #18ffff !important
}

.bg-cyan-accent-3 {
  background-color: #00e5ff !important;
  color: #000 !important
}

.text-cyan-accent-3 {
  color: #00e5ff !important;
  caret-color: #00e5ff !important
}

.bg-cyan-accent-4 {
  background-color: #00b8d4 !important;
  color: #000 !important
}

.text-cyan-accent-4 {
  color: #00b8d4 !important;
  caret-color: #00b8d4 !important
}

.bg-teal {
  background-color: #009688 !important;
  color: #000 !important
}

.text-teal {
  color: #009688 !important;
  caret-color: #009688 !important
}

.bg-teal-lighten-5 {
  background-color: #e0f2f1 !important;
  color: #000 !important
}

.text-teal-lighten-5 {
  color: #e0f2f1 !important;
  caret-color: #e0f2f1 !important
}

.bg-teal-lighten-4 {
  background-color: #b2dfdb !important;
  color: #000 !important
}

.text-teal-lighten-4 {
  color: #b2dfdb !important;
  caret-color: #b2dfdb !important
}

.bg-teal-lighten-3 {
  background-color: #80cbc4 !important;
  color: #000 !important
}

.text-teal-lighten-3 {
  color: #80cbc4 !important;
  caret-color: #80cbc4 !important
}

.bg-teal-lighten-2 {
  background-color: #4db6ac !important;
  color: #000 !important
}

.text-teal-lighten-2 {
  color: #4db6ac !important;
  caret-color: #4db6ac !important
}

.bg-teal-lighten-1 {
  background-color: #26a69a !important;
  color: #000 !important
}

.text-teal-lighten-1 {
  color: #26a69a !important;
  caret-color: #26a69a !important
}

.bg-teal-darken-1 {
  background-color: #00897b !important;
  color: #000 !important
}

.text-teal-darken-1 {
  color: #00897b !important;
  caret-color: #00897b !important
}

.bg-teal-darken-2 {
  background-color: #00796b !important;
  color: #fff !important
}

.text-teal-darken-2 {
  color: #00796b !important;
  caret-color: #00796b !important
}

.bg-teal-darken-3 {
  background-color: #00695c !important;
  color: #fff !important
}

.text-teal-darken-3 {
  color: #00695c !important;
  caret-color: #00695c !important
}

.bg-teal-darken-4 {
  background-color: #004d40 !important;
  color: #fff !important
}

.text-teal-darken-4 {
  color: #004d40 !important;
  caret-color: #004d40 !important
}

.bg-teal-accent-1 {
  background-color: #a7ffeb !important;
  color: #000 !important
}

.text-teal-accent-1 {
  color: #a7ffeb !important;
  caret-color: #a7ffeb !important
}

.bg-teal-accent-2 {
  background-color: #64ffda !important;
  color: #000 !important
}

.text-teal-accent-2 {
  color: #64ffda !important;
  caret-color: #64ffda !important
}

.bg-teal-accent-3 {
  background-color: #1de9b6 !important;
  color: #000 !important
}

.text-teal-accent-3 {
  color: #1de9b6 !important;
  caret-color: #1de9b6 !important
}

.bg-teal-accent-4 {
  background-color: #00bfa5 !important;
  color: #000 !important
}

.text-teal-accent-4 {
  color: #00bfa5 !important;
  caret-color: #00bfa5 !important
}

.bg-green {
  background-color: #4caf50 !important;
  color: #000 !important
}

.text-green {
  color: #4caf50 !important;
  caret-color: #4caf50 !important
}

.bg-green-lighten-5 {
  background-color: #e8f5e9 !important;
  color: #000 !important
}

.text-green-lighten-5 {
  color: #e8f5e9 !important;
  caret-color: #e8f5e9 !important
}

.bg-green-lighten-4 {
  background-color: #c8e6c9 !important;
  color: #000 !important
}

.text-green-lighten-4 {
  color: #c8e6c9 !important;
  caret-color: #c8e6c9 !important
}

.bg-green-lighten-3 {
  background-color: #a5d6a7 !important;
  color: #000 !important
}

.text-green-lighten-3 {
  color: #a5d6a7 !important;
  caret-color: #a5d6a7 !important
}

.bg-green-lighten-2 {
  background-color: #81c784 !important;
  color: #000 !important
}

.text-green-lighten-2 {
  color: #81c784 !important;
  caret-color: #81c784 !important
}

.bg-green-lighten-1 {
  background-color: #66bb6a !important;
  color: #000 !important
}

.text-green-lighten-1 {
  color: #66bb6a !important;
  caret-color: #66bb6a !important
}

.bg-green-darken-1 {
  background-color: #43a047 !important;
  color: #000 !important
}

.text-green-darken-1 {
  color: #43a047 !important;
  caret-color: #43a047 !important
}

.bg-green-darken-2 {
  background-color: #388e3c !important;
  color: #000 !important
}

.text-green-darken-2 {
  color: #388e3c !important;
  caret-color: #388e3c !important
}

.bg-green-darken-3 {
  background-color: #2e7d32 !important;
  color: #fff !important
}

.text-green-darken-3 {
  color: #2e7d32 !important;
  caret-color: #2e7d32 !important
}

.bg-green-darken-4 {
  background-color: #1b5e20 !important;
  color: #fff !important
}

.text-green-darken-4 {
  color: #1b5e20 !important;
  caret-color: #1b5e20 !important
}

.bg-green-accent-1 {
  background-color: #b9f6ca !important;
  color: #000 !important
}

.text-green-accent-1 {
  color: #b9f6ca !important;
  caret-color: #b9f6ca !important
}

.bg-green-accent-2 {
  background-color: #69f0ae !important;
  color: #fff !important
}

.text-green-accent-2 {
  color: #69f0ae !important;
  caret-color: #69f0ae !important
}

.bg-green-accent-3 {
  background-color: #00e676 !important;
  color: #fff !important
}

.text-green-accent-3 {
  color: #00e676 !important;
  caret-color: #00e676 !important
}

.bg-green-accent-4 {
  background-color: #00c853 !important;
  color: #000 !important
}

.text-green-accent-4 {
  color: #00c853 !important;
  caret-color: #00c853 !important
}

.bg-light-green {
  background-color: #8bc34a !important;
  color: #000 !important
}

.text-light-green {
  color: #8bc34a !important;
  caret-color: #8bc34a !important
}

.bg-light-green-lighten-5 {
  background-color: #f1f8e9 !important;
  color: #000 !important
}

.text-light-green-lighten-5 {
  color: #f1f8e9 !important;
  caret-color: #f1f8e9 !important
}

.bg-light-green-lighten-4 {
  background-color: #dcedc8 !important;
  color: #000 !important
}

.text-light-green-lighten-4 {
  color: #dcedc8 !important;
  caret-color: #dcedc8 !important
}

.bg-light-green-lighten-3 {
  background-color: #c5e1a5 !important;
  color: #000 !important
}

.text-light-green-lighten-3 {
  color: #c5e1a5 !important;
  caret-color: #c5e1a5 !important
}

.bg-light-green-lighten-2 {
  background-color: #aed581 !important;
  color: #000 !important
}

.text-light-green-lighten-2 {
  color: #aed581 !important;
  caret-color: #aed581 !important
}

.bg-light-green-lighten-1 {
  background-color: #9ccc65 !important;
  color: #000 !important
}

.text-light-green-lighten-1 {
  color: #9ccc65 !important;
  caret-color: #9ccc65 !important
}

.bg-light-green-darken-1 {
  background-color: #7cb342 !important;
  color: #000 !important
}

.text-light-green-darken-1 {
  color: #7cb342 !important;
  caret-color: #7cb342 !important
}

.bg-light-green-darken-2 {
  background-color: #689f38 !important;
  color: #000 !important
}

.text-light-green-darken-2 {
  color: #689f38 !important;
  caret-color: #689f38 !important
}

.bg-light-green-darken-3 {
  background-color: #558b2f !important;
  color: #000 !important
}

.text-light-green-darken-3 {
  color: #558b2f !important;
  caret-color: #558b2f !important
}

.bg-light-green-darken-4 {
  background-color: #33691e !important;
  color: #fff !important
}

.text-light-green-darken-4 {
  color: #33691e !important;
  caret-color: #33691e !important
}

.bg-light-green-accent-1 {
  background-color: #ccff90 !important;
  color: #000 !important
}

.text-light-green-accent-1 {
  color: #ccff90 !important;
  caret-color: #ccff90 !important
}

.bg-light-green-accent-2 {
  background-color: #b2ff59 !important;
  color: #000 !important
}

.text-light-green-accent-2 {
  color: #b2ff59 !important;
  caret-color: #b2ff59 !important
}

.bg-light-green-accent-3 {
  background-color: #76ff03 !important;
  color: #000 !important
}

.text-light-green-accent-3 {
  color: #76ff03 !important;
  caret-color: #76ff03 !important
}

.bg-light-green-accent-4 {
  background-color: #64dd17 !important;
  color: #000 !important
}

.text-light-green-accent-4 {
  color: #64dd17 !important;
  caret-color: #64dd17 !important
}

.bg-lime {
  background-color: #cddc39 !important;
  color: #000 !important
}

.text-lime {
  color: #cddc39 !important;
  caret-color: #cddc39 !important
}

.bg-lime-lighten-5 {
  background-color: #f9fbe7 !important;
  color: #000 !important
}

.text-lime-lighten-5 {
  color: #f9fbe7 !important;
  caret-color: #f9fbe7 !important
}

.bg-lime-lighten-4 {
  background-color: #f0f4c3 !important;
  color: #000 !important
}

.text-lime-lighten-4 {
  color: #f0f4c3 !important;
  caret-color: #f0f4c3 !important
}

.bg-lime-lighten-3 {
  background-color: #e6ee9c !important;
  color: #000 !important
}

.text-lime-lighten-3 {
  color: #e6ee9c !important;
  caret-color: #e6ee9c !important
}

.bg-lime-lighten-2 {
  background-color: #dce775 !important;
  color: #000 !important
}

.text-lime-lighten-2 {
  color: #dce775 !important;
  caret-color: #dce775 !important
}

.bg-lime-lighten-1 {
  background-color: #d4e157 !important;
  color: #000 !important
}

.text-lime-lighten-1 {
  color: #d4e157 !important;
  caret-color: #d4e157 !important
}

.bg-lime-darken-1 {
  background-color: #c0ca33 !important;
  color: #000 !important
}

.text-lime-darken-1 {
  color: #c0ca33 !important;
  caret-color: #c0ca33 !important
}

.bg-lime-darken-2 {
  background-color: #afb42b !important;
  color: #000 !important
}

.text-lime-darken-2 {
  color: #afb42b !important;
  caret-color: #afb42b !important
}

.bg-lime-darken-3 {
  background-color: #9e9d24 !important;
  color: #000 !important
}

.text-lime-darken-3 {
  color: #9e9d24 !important;
  caret-color: #9e9d24 !important
}

.bg-lime-darken-4 {
  background-color: #827717 !important;
  color: #000 !important
}

.text-lime-darken-4 {
  color: #827717 !important;
  caret-color: #827717 !important
}

.bg-lime-accent-1 {
  background-color: #f4ff81 !important;
  color: #000 !important
}

.text-lime-accent-1 {
  color: #f4ff81 !important;
  caret-color: #f4ff81 !important
}

.bg-lime-accent-2 {
  background-color: #eeff41 !important;
  color: #000 !important
}

.text-lime-accent-2 {
  color: #eeff41 !important;
  caret-color: #eeff41 !important
}

.bg-lime-accent-3 {
  background-color: #c6ff00 !important;
  color: #000 !important
}

.text-lime-accent-3 {
  color: #c6ff00 !important;
  caret-color: #c6ff00 !important
}

.bg-lime-accent-4 {
  background-color: #aeea00 !important;
  color: #000 !important
}

.text-lime-accent-4 {
  color: #aeea00 !important;
  caret-color: #aeea00 !important
}

.bg-yellow {
  background-color: #ffeb3b !important;
  color: #000 !important
}

.text-yellow {
  color: #ffeb3b !important;
  caret-color: #ffeb3b !important
}

.bg-yellow-lighten-5 {
  background-color: #fffde7 !important;
  color: #000 !important
}

.text-yellow-lighten-5 {
  color: #fffde7 !important;
  caret-color: #fffde7 !important
}

.bg-yellow-lighten-4 {
  background-color: #fff9c4 !important;
  color: #000 !important
}

.text-yellow-lighten-4 {
  color: #fff9c4 !important;
  caret-color: #fff9c4 !important
}

.bg-yellow-lighten-3 {
  background-color: #fff59d !important;
  color: #000 !important
}

.text-yellow-lighten-3 {
  color: #fff59d !important;
  caret-color: #fff59d !important
}

.bg-yellow-lighten-2 {
  background-color: #fff176 !important;
  color: #000 !important
}

.text-yellow-lighten-2 {
  color: #fff176 !important;
  caret-color: #fff176 !important
}

.bg-yellow-lighten-1 {
  background-color: #ffee58 !important;
  color: #000 !important
}

.text-yellow-lighten-1 {
  color: #ffee58 !important;
  caret-color: #ffee58 !important
}

.bg-yellow-darken-1 {
  background-color: #fdd835 !important;
  color: #000 !important
}

.text-yellow-darken-1 {
  color: #fdd835 !important;
  caret-color: #fdd835 !important
}

.bg-yellow-darken-2 {
  background-color: #fbc02d !important;
  color: #000 !important
}

.text-yellow-darken-2 {
  color: #fbc02d !important;
  caret-color: #fbc02d !important
}

.bg-yellow-darken-3 {
  background-color: #f9a825 !important;
  color: #000 !important
}

.text-yellow-darken-3 {
  color: #f9a825 !important;
  caret-color: #f9a825 !important
}

.bg-yellow-darken-4 {
  background-color: #f57f17 !important;
  color: #000 !important
}

.text-yellow-darken-4 {
  color: #f57f17 !important;
  caret-color: #f57f17 !important
}

.bg-yellow-accent-1 {
  background-color: #ffff8d !important;
  color: #000 !important
}

.text-yellow-accent-1 {
  color: #ffff8d !important;
  caret-color: #ffff8d !important
}

.bg-yellow-accent-2 {
  background-color: #ff0 !important;
  color: #000 !important
}

.text-yellow-accent-2 {
  color: #ff0 !important;
  caret-color: #ff0 !important
}

.bg-yellow-accent-3 {
  background-color: #ffea00 !important;
  color: #000 !important
}

.text-yellow-accent-3 {
  color: #ffea00 !important;
  caret-color: #ffea00 !important
}

.bg-yellow-accent-4 {
  background-color: #ffd600 !important;
  color: #000 !important
}

.text-yellow-accent-4 {
  color: #ffd600 !important;
  caret-color: #ffd600 !important
}

.bg-amber {
  background-color: #ffc107 !important;
  color: #000 !important
}

.text-amber {
  color: #ffc107 !important;
  caret-color: #ffc107 !important
}

.bg-amber-lighten-5 {
  background-color: #fff8e1 !important;
  color: #000 !important
}

.text-amber-lighten-5 {
  color: #fff8e1 !important;
  caret-color: #fff8e1 !important
}

.bg-amber-lighten-4 {
  background-color: #ffecb3 !important;
  color: #000 !important
}

.text-amber-lighten-4 {
  color: #ffecb3 !important;
  caret-color: #ffecb3 !important
}

.bg-amber-lighten-3 {
  background-color: #ffe082 !important;
  color: #000 !important
}

.text-amber-lighten-3 {
  color: #ffe082 !important;
  caret-color: #ffe082 !important
}

.bg-amber-lighten-2 {
  background-color: #ffd54f !important;
  color: #000 !important
}

.text-amber-lighten-2 {
  color: #ffd54f !important;
  caret-color: #ffd54f !important
}

.bg-amber-lighten-1 {
  background-color: #ffca28 !important;
  color: #000 !important
}

.text-amber-lighten-1 {
  color: #ffca28 !important;
  caret-color: #ffca28 !important
}

.bg-amber-darken-1 {
  background-color: #ffb300 !important;
  color: #000 !important
}

.text-amber-darken-1 {
  color: #ffb300 !important;
  caret-color: #ffb300 !important
}

.bg-amber-darken-2 {
  background-color: #ffa000 !important;
  color: #000 !important
}

.text-amber-darken-2 {
  color: #ffa000 !important;
  caret-color: #ffa000 !important
}

.bg-amber-darken-3 {
  background-color: #ff8f00 !important;
  color: #000 !important
}

.text-amber-darken-3 {
  color: #ff8f00 !important;
  caret-color: #ff8f00 !important
}

.bg-amber-darken-4 {
  background-color: #ff6f00 !important;
  color: #000 !important
}

.text-amber-darken-4 {
  color: #ff6f00 !important;
  caret-color: #ff6f00 !important
}

.bg-amber-accent-1 {
  background-color: #ffe57f !important;
  color: #000 !important
}

.text-amber-accent-1 {
  color: #ffe57f !important;
  caret-color: #ffe57f !important
}

.bg-amber-accent-2 {
  background-color: #ffd740 !important;
  color: #000 !important
}

.text-amber-accent-2 {
  color: #ffd740 !important;
  caret-color: #ffd740 !important
}

.bg-amber-accent-3 {
  background-color: #ffc400 !important;
  color: #000 !important
}

.text-amber-accent-3 {
  color: #ffc400 !important;
  caret-color: #ffc400 !important
}

.bg-amber-accent-4 {
  background-color: #ffab00 !important;
  color: #000 !important
}

.text-amber-accent-4 {
  color: #ffab00 !important;
  caret-color: #ffab00 !important
}

.bg-orange {
  background-color: #ff9800 !important;
  color: #000 !important
}

.text-orange {
  color: #ff9800 !important;
  caret-color: #ff9800 !important
}

.bg-orange-lighten-5 {
  background-color: #fff3e0 !important;
  color: #000 !important
}

.text-orange-lighten-5 {
  color: #fff3e0 !important;
  caret-color: #fff3e0 !important
}

.bg-orange-lighten-4 {
  background-color: #ffe0b2 !important;
  color: #000 !important
}

.text-orange-lighten-4 {
  color: #ffe0b2 !important;
  caret-color: #ffe0b2 !important
}

.bg-orange-lighten-3 {
  background-color: #ffcc80 !important;
  color: #000 !important
}

.text-orange-lighten-3 {
  color: #ffcc80 !important;
  caret-color: #ffcc80 !important
}

.bg-orange-lighten-2 {
  background-color: #ffb74d !important;
  color: #000 !important
}

.text-orange-lighten-2 {
  color: #ffb74d !important;
  caret-color: #ffb74d !important
}

.bg-orange-lighten-1 {
  background-color: #ffa726 !important;
  color: #000 !important
}

.text-orange-lighten-1 {
  color: #ffa726 !important;
  caret-color: #ffa726 !important
}

.bg-orange-darken-1 {
  background-color: #fb8c00 !important;
  color: #000 !important
}

.text-orange-darken-1 {
  color: #fb8c00 !important;
  caret-color: #fb8c00 !important
}

.bg-orange-darken-2 {
  background-color: #f57c00 !important;
  color: #000 !important
}

.text-orange-darken-2 {
  color: #f57c00 !important;
  caret-color: #f57c00 !important
}

.bg-orange-darken-3 {
  background-color: #ef6c00 !important;
  color: #000 !important
}

.text-orange-darken-3 {
  color: #ef6c00 !important;
  caret-color: #ef6c00 !important
}

.bg-orange-darken-4 {
  background-color: #e65100 !important;
  color: #000 !important
}

.text-orange-darken-4 {
  color: #e65100 !important;
  caret-color: #e65100 !important
}

.bg-orange-accent-1 {
  background-color: #ffd180 !important;
  color: #000 !important
}

.text-orange-accent-1 {
  color: #ffd180 !important;
  caret-color: #ffd180 !important
}

.bg-orange-accent-2 {
  background-color: #ffab40 !important;
  color: #000 !important
}

.text-orange-accent-2 {
  color: #ffab40 !important;
  caret-color: #ffab40 !important
}

.bg-orange-accent-3 {
  background-color: #ff9100 !important;
  color: #000 !important
}

.text-orange-accent-3 {
  color: #ff9100 !important;
  caret-color: #ff9100 !important
}

.bg-orange-accent-4 {
  background-color: #ff6d00 !important;
  color: #000 !important
}

.text-orange-accent-4 {
  color: #ff6d00 !important;
  caret-color: #ff6d00 !important
}

.bg-deep-orange {
  background-color: #ff5722 !important;
  color: #000 !important
}

.text-deep-orange {
  color: #ff5722 !important;
  caret-color: #ff5722 !important
}

.bg-deep-orange-lighten-5 {
  background-color: #fbe9e7 !important;
  color: #000 !important
}

.text-deep-orange-lighten-5 {
  color: #fbe9e7 !important;
  caret-color: #fbe9e7 !important
}

.bg-deep-orange-lighten-4 {
  background-color: #ffccbc !important;
  color: #000 !important
}

.text-deep-orange-lighten-4 {
  color: #ffccbc !important;
  caret-color: #ffccbc !important
}

.bg-deep-orange-lighten-3 {
  background-color: #ffab91 !important;
  color: #000 !important
}

.text-deep-orange-lighten-3 {
  color: #ffab91 !important;
  caret-color: #ffab91 !important
}

.bg-deep-orange-lighten-2 {
  background-color: #ff8a65 !important;
  color: #000 !important
}

.text-deep-orange-lighten-2 {
  color: #ff8a65 !important;
  caret-color: #ff8a65 !important
}

.bg-deep-orange-lighten-1 {
  background-color: #ff7043 !important;
  color: #000 !important
}

.text-deep-orange-lighten-1 {
  color: #ff7043 !important;
  caret-color: #ff7043 !important
}

.bg-deep-orange-darken-1 {
  background-color: #f4511e !important;
  color: #000 !important
}

.text-deep-orange-darken-1 {
  color: #f4511e !important;
  caret-color: #f4511e !important
}

.bg-deep-orange-darken-2 {
  background-color: #e64a19 !important;
  color: #000 !important
}

.text-deep-orange-darken-2 {
  color: #e64a19 !important;
  caret-color: #e64a19 !important
}

.bg-deep-orange-darken-3 {
  background-color: #d84315 !important;
  color: #000 !important
}

.text-deep-orange-darken-3 {
  color: #d84315 !important;
  caret-color: #d84315 !important
}

.bg-deep-orange-darken-4 {
  background-color: #bf360c !important;
  color: #fff !important
}

.text-deep-orange-darken-4 {
  color: #bf360c !important;
  caret-color: #bf360c !important
}

.bg-deep-orange-accent-1 {
  background-color: #ff9e80 !important;
  color: #000 !important
}

.text-deep-orange-accent-1 {
  color: #ff9e80 !important;
  caret-color: #ff9e80 !important
}

.bg-deep-orange-accent-2 {
  background-color: #ff6e40 !important;
  color: #000 !important
}

.text-deep-orange-accent-2 {
  color: #ff6e40 !important;
  caret-color: #ff6e40 !important
}

.bg-deep-orange-accent-3 {
  background-color: #ff3d00 !important;
  color: #000 !important
}

.text-deep-orange-accent-3 {
  color: #ff3d00 !important;
  caret-color: #ff3d00 !important
}

.bg-deep-orange-accent-4 {
  background-color: #dd2c00 !important;
  color: #fff !important
}

.text-deep-orange-accent-4 {
  color: #dd2c00 !important;
  caret-color: #dd2c00 !important
}

.bg-brown {
  background-color: #795548 !important;
  color: #fff !important
}

.text-brown {
  color: #795548 !important;
  caret-color: #795548 !important
}

.bg-brown-lighten-5 {
  background-color: #efebe9 !important;
  color: #000 !important
}

.text-brown-lighten-5 {
  color: #efebe9 !important;
  caret-color: #efebe9 !important
}

.bg-brown-lighten-4 {
  background-color: #d7ccc8 !important;
  color: #000 !important
}

.text-brown-lighten-4 {
  color: #d7ccc8 !important;
  caret-color: #d7ccc8 !important
}

.bg-brown-lighten-3 {
  background-color: #bcaaa4 !important;
  color: #000 !important
}

.text-brown-lighten-3 {
  color: #bcaaa4 !important;
  caret-color: #bcaaa4 !important
}

.bg-brown-lighten-2 {
  background-color: #a1887f !important;
  color: #000 !important
}

.text-brown-lighten-2 {
  color: #a1887f !important;
  caret-color: #a1887f !important
}

.bg-brown-lighten-1 {
  background-color: #8d6e63 !important;
  color: #fff !important
}

.text-brown-lighten-1 {
  color: #8d6e63 !important;
  caret-color: #8d6e63 !important
}

.bg-brown-darken-1 {
  background-color: #6d4c41 !important;
  color: #fff !important
}

.text-brown-darken-1 {
  color: #6d4c41 !important;
  caret-color: #6d4c41 !important
}

.bg-brown-darken-2 {
  background-color: #5d4037 !important;
  color: #fff !important
}

.text-brown-darken-2 {
  color: #5d4037 !important;
  caret-color: #5d4037 !important
}

.bg-brown-darken-3 {
  background-color: #4e342e !important;
  color: #fff !important
}

.text-brown-darken-3 {
  color: #4e342e !important;
  caret-color: #4e342e !important
}

.bg-brown-darken-4 {
  background-color: #3e2723 !important;
  color: #fff !important
}

.text-brown-darken-4 {
  color: #3e2723 !important;
  caret-color: #3e2723 !important
}

.bg-blue-grey {
  background-color: #607d8b !important;
  color: #000 !important
}

.text-blue-grey {
  color: #607d8b !important;
  caret-color: #607d8b !important
}

.bg-blue-grey-lighten-5 {
  background-color: #eceff1 !important;
  color: #000 !important
}

.text-blue-grey-lighten-5 {
  color: #eceff1 !important;
  caret-color: #eceff1 !important
}

.bg-blue-grey-lighten-4 {
  background-color: #cfd8dc !important;
  color: #000 !important
}

.text-blue-grey-lighten-4 {
  color: #cfd8dc !important;
  caret-color: #cfd8dc !important
}

.bg-blue-grey-lighten-3 {
  background-color: #b0bec5 !important;
  color: #000 !important
}

.text-blue-grey-lighten-3 {
  color: #b0bec5 !important;
  caret-color: #b0bec5 !important
}

.bg-blue-grey-lighten-2 {
  background-color: #90a4ae !important;
  color: #000 !important
}

.text-blue-grey-lighten-2 {
  color: #90a4ae !important;
  caret-color: #90a4ae !important
}

.bg-blue-grey-lighten-1 {
  background-color: #78909c !important;
  color: #000 !important
}

.text-blue-grey-lighten-1 {
  color: #78909c !important;
  caret-color: #78909c !important
}

.bg-blue-grey-darken-1 {
  background-color: #546e7a !important;
  color: #fff !important
}

.text-blue-grey-darken-1 {
  color: #546e7a !important;
  caret-color: #546e7a !important
}

.bg-blue-grey-darken-2 {
  background-color: #455a64 !important;
  color: #fff !important
}

.text-blue-grey-darken-2 {
  color: #455a64 !important;
  caret-color: #455a64 !important
}

.bg-blue-grey-darken-3 {
  background-color: #37474f !important;
  color: #fff !important
}

.text-blue-grey-darken-3 {
  color: #37474f !important;
  caret-color: #37474f !important
}

.bg-blue-grey-darken-4 {
  background-color: #263238 !important;
  color: #fff !important
}

.text-blue-grey-darken-4 {
  color: #263238 !important;
  caret-color: #263238 !important
}

.bg-grey {
  background-color: #9e9e9e !important;
  color: #000 !important
}

.text-grey {
  color: #9e9e9e !important;
  caret-color: #9e9e9e !important
}

.bg-grey-lighten-5 {
  background-color: #fafafa !important;
  color: #000 !important
}

.text-grey-lighten-5 {
  color: #fafafa !important;
  caret-color: #fafafa !important
}

.bg-grey-lighten-4 {
  background-color: #f5f5f5 !important;
  color: #000 !important
}

.text-grey-lighten-4 {
  color: #f5f5f5 !important;
  caret-color: #f5f5f5 !important
}

.bg-grey-lighten-3 {
  background-color: #eee !important;
  color: #000 !important
}

.text-grey-lighten-3 {
  color: #eee !important;
  caret-color: #eee !important
}

.bg-grey-lighten-2 {
  background-color: #e0e0e0 !important;
  color: #000 !important
}

.text-grey-lighten-2 {
  color: #e0e0e0 !important;
  caret-color: #e0e0e0 !important
}

.bg-grey-lighten-1 {
  background-color: #bdbdbd !important;
  color: #000 !important
}

.text-grey-lighten-1 {
  color: #bdbdbd !important;
  caret-color: #bdbdbd !important
}

.bg-grey-darken-1 {
  background-color: #757575 !important;
  color: #fff !important
}

.text-grey-darken-1 {
  color: #757575 !important;
  caret-color: #757575 !important
}

.bg-grey-darken-2 {
  background-color: #616161 !important;
  color: #fff !important
}

.text-grey-darken-2 {
  color: #616161 !important;
  caret-color: #616161 !important
}

.bg-grey-darken-3 {
  background-color: #424242 !important;
  color: #fff !important
}

.text-grey-darken-3 {
  color: #424242 !important;
  caret-color: #424242 !important
}

.bg-grey-darken-4 {
  background-color: #212121 !important;
  color: #fff !important
}

.text-grey-darken-4 {
  color: #212121 !important;
  caret-color: #212121 !important
}

.bg-shades-black {
  background-color: #000 !important;
  color: #fff !important
}

.text-shades-black {
  color: #000 !important;
  caret-color: #000 !important
}

.bg-shades-white {
  background-color: #fff !important;
  color: #000 !important
}

.text-shades-white {
  color: #fff !important;
  caret-color: #fff !important
}

.bg-shades-transparent {
  background-color: transparent !important;
  color: currentColor !important
}

.text-shades-transparent {
  color: transparent !important;
  caret-color: transparent !important
}


.landing__banner {
  top: 0;
  left: 0;
  width: 100%;
  position: relative;
  padding: 80px 0 50px
}

@media (min-width: 960px) {
  .landing__banner {
    padding-top: 130px
  }
}

.landing__banner .landing__text {
  max-width: 655px
}

.landing__banner .banner__title {
  font-size: 2rem;
  margin: 0 0 1rem;
  line-height: 1.1;
  font-weight: 700;
  padding-right: 24px;
  font-family: filson-soft, sans-serif;
  text-transform: uppercase
}

@media (min-width: 960px) and (max-width: 1279.98px) {
  .landing__banner .banner__title {
    font-size: 2.5rem;
    line-height: 3.125rem
  }
}

@media (min-width: 1280px) {
  .landing__banner .banner__title {
    font-size: 4.5rem;
    line-height: 5.375rem
  }
}

.landing__banner .banner__title span {
  display: block
}

.landing__banner .banner__image {
  position: relative;
  margin-bottom: 2rem;
  width: 100%;
  max-width: 350px
}

.landing__banner .banner__image img,
.landing__banner .banner__image video {
  width: 100%;
  transition: opacity 1s ease-in-out
}

.landing__banner .banner__image:not(.is-loaded) img,
.landing__banner .banner__image:not(.is-loaded) video,
.landing__banner .banner__image:not(.is-loaded):after {
  opacity: 0
}

.landing__banner .banner__image .image {
  aspect-ratio: 1;
  width: 100%;
  overflow: hidden;
  border-radius: 20px
}

.landing__banner .banner__image:after {
  content: "";
  display: block;
  width: 40%;
  aspect-ratio: 1;
  border-radius: 50%;
  background: rgb(255, 244, 245);
  filter: blur(80px);
  transform: rotate(-2.2deg);
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  animation: glow-moving 3s linear alternate infinite;
  transition: opacity 1s ease-in-out
}

.landing__banner .banner__buttons {
  margin-top: 1rem;
  display: flex;
  align-items: center
}

.landing__banner .banner__buttons .v-btn {
  margin-top: 1rem
}

.landing__banner .banner__buttons .v-btn:first-child {
  margin-right: 1rem
}

.landing__banner .btn__spinner {
  margin-top: 1rem;
  margin-right: 1rem
}

@media (max-width: 959.98px) {

  .landing__banner .banner__image,
  .landing__banner .banner__text {
    margin-left: auto;
    margin-right: auto;
    max-width: 400px
  }

  .landing__banner .btn__spinner {
    width: 25px
  }
}

@media (min-width: 960px) {
  .landing__banner .banner__content {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    align-content: center
  }

  .landing__banner .banner__title {
    margin-bottom: 2rem
  }

  .landing__banner .banner__image {
    margin-bottom: 0
  }
}

@media (min-width: 1280px) {
  .landing__banner .banner__image {
    max-width: 500px
  }

  .landing__banner .banner__buttons .v-btn {
    font-size: 1rem;
    height: 48px;
    padding: 0 30px
  }
}

@media (min-width: 1920px) {
  .landing__banner .banner__buttons .v-btn {
    font-size: 1.25rem;
    height: 60px
  }
}

.landing__banner .carousel__slide {
  padding: 20px;
  display: flex
}

@media (max-width: 959.98px) {
  .landing__banner .carousel__slide {
    padding: 25px
  }
}

@media (max-width: 599.98px) {
  .landing__banner .carousel__slide {
    padding: 30px
  }
}

.landing__banner .carousel__slide .stat__item {
  align-self: stretch
}

@media (min-width: 960px) {
  .landing__banner .carousel__pagination {
    display: none
  }
}

@keyframes glow-moving {
  0% {
    transform: translate(-20px, -20px)
  }

  50% {
    transform: translate(20px, -20px)
  }

  to {
    transform: translate(20px, 20px)
  }
}

.banner__list {
  margin-top: 80px;
  margin-bottom: 80px;
  justify-content: center
}

@media (min-width: 960px) {
  .banner__list {
    margin: 80px -60px 0
  }
}

@media (max-width: 620px) {
.landing__banner .banner__image {
  display: none !important;
}
.landing__banner .banner__text {
  text-align: center;
}
.landing__banner .banner__buttons {
  place-content: center;
}
}

.banner__list .stat__item {
  padding: 24px 24px 28px;
  width: 100%;
  background: linear-gradient(109.46deg, rgba(245, 245, 245, .12) 1.57%, rgba(255, 255, 255, .045) 100%);
  box-shadow: 0 0 1px #cdcdff66, 0 2px 15px #cdcdff40;
  backdrop-filter: blur(30px);
  line-height: 1;
  align-self: stretch;
  border-radius: 27px;
  text-align: center
}

.banner__list .stat__item:before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 27px;
  padding: 1px;
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude
}

.banner__list .stat__item .img-wrapper.mb-4 {
  max-width: 200px;
  max-height: 200px;
  margin-left: auto;
  margin-right: auto
}

@media (min-width: 1280px) {
  .banner__list .stat__item {
    padding: 48px 48px 56px
  }
}

.banner__list .stat__item .title {
  max-width: calc(100% - 15px);
  margin-left: 10px;
  font-size: 3rem;
  font-weight: 700;
  font-family: filson-soft, sans-serif
}

@media (min-width: 1920px) {
  .banner__list .stat__item .title {
    font-size: 3.5rem
  }
}

.banner__list .stat__item .svg,
.banner__list .stat__item .title {
  display: inline-block;
  vertical-align: middle
}

.banner__list .stat__item .text {
  margin-top: 8px;
  opacity: .8
}

@media (min-width: 1920px) {
  .banner__list .stat__item .text {
    font-size: 1.125rem
  }
}

.rewards__item[data-v-49495f34] {
  position: relative;
  width: 100%;
  background: linear-gradient(129.45deg, rgba(156, 255, 196, .4) 27.52%, rgba(30, 63, 183, .325) 100%);
  backdrop-filter: blur(30px);
  display: inline-block;
  border-radius: 27px;
  padding: 16px;
  font-size: 1.25rem;
  font-weight: 200;
  line-height: 1.4;
  text-align: center;
  word-break: break-word;
  white-space: break-spaces;
  transition: transform .5s cubic-bezier(0, 0, 0, 1)
}

.rewards__item[data-v-49495f34]:before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 27px;
  padding: 1px;
  background: linear-gradient(109.46deg, rgba(245, 245, 245, .12) 1.57%, rgba(255, 255, 255, .045) 100%), linear-gradient(108.87deg, rgba(255, 255, 255, .95) .66%, rgba(211, 211, 211, .1) 99.48%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude
}

@media (min-width: 600px) {
  .carousel__slide--active .rewards__item[data-v-49495f34] {
    transform: scale(1.07)
  }

  .carousel__slide--active~.carousel__slide .rewards__item[data-v-49495f34] {
    transform: translate(10px)
  }
}

.rewards__item .base-card__image[data-v-49495f34] {
  margin: 0;
  width: 100%;
  border-radius: 25px;
  aspect-ratio: 1;
  overflow: hidden
}

.rewards__item .v-img[data-v-49495f34] {
  width: 100%;
  aspect-ratio: 1
}

.rewards__item .title[data-v-49495f34] {
  font-size: 1.25rem;
  line-height: 1.3;
  min-height: 65px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  text-align: center;
  letter-spacing: .04em;
  text-transform: uppercase;
  margin-top: 10px;
  margin-bottom: 4px
}

@media (max-width: 599.98px) {
  .rewards__item .title[data-v-49495f34] {
    font-size: 1.5625rem
  }
}

@media (min-width: 1280px) {
  .rewards__item .title[data-v-49495f34] {
    font-size: 1.5625rem
  }
}

.rewards__item .text[data-v-49495f34] {
  font-weight: 600;
  font-size: .875rem;
  line-height: 1.375rem
}
.top-margins-landing {
  margin-top: 10%;
}
.landing__rewards {
  padding: 40px 0 50px;
  --rewards-width: 200px;
  --rewards-gutter: 10px
}

@media (min-width: 960px) {
  .landing__rewards {
    --rewards-width: 260px;
    --rewards-gutter: 15px;
    padding: 100px 0 150px
  }
}

.landing__rewards .rewards__list {
  overflow: visible;
  margin-top: 70px
}

@media (min-width: 960px) {
  .landing__rewards .rewards__list {
    transform: none;
    margin-top: 48px
  }
}



@media (max-width: 1279.98px) {

  .landing__rewards .carousel__next,
  .landing__rewards .carousel__prev {
    top: 0;
    width: 48px;
    height: 48px
  }
}

.landing__rewards .carousel__next:before,
.landing__rewards .carousel__prev:before {
  font-size: 1.25rem
}

.landing__rewards .carousel__next .carousel__icon,
.landing__rewards .carousel__prev .carousel__icon {
  display: none
}

.landing__rewards .carousel__prev {
  transform: translate(-110%, -50%)
}

.landing__rewards .carousel__prev:before {
  content: "\e912";
  font-family: icomoon;
  display: block
}

@media (max-width: 1279.98px) {
  .landing__rewards .carousel__prev {
    right: 60px;
    left: auto;
    transform: translateY(-100%)
  }
}

.landing__rewards .carousel__next {
  transform: translate(110%, -50%)
}

.landing__rewards .carousel__next:before {
  content: "\e911";
  font-family: icomoon;
  display: block
}

@media (max-width: 1279.98px) {
  .landing__rewards .carousel__next {
    right: 0;
    transform: translateY(-100%)
  }
}

.landing__rewards .carousel__slide {
  display: flex;
  padding: var(--rewards-gutter)
}

.landing__rewards .carousel__slide .rewards__item {
  align-self: stretch
}

@media (min-width: 600px) {
  .landing__rewards .carousel__slide {
    padding: calc(var(--rewards-gutter) * 2) var(--rewards-gutter)
  }
}

.landing__rewards .carousel__prev--in-active,
.landing__rewards .carousel__next--in-active {
  opacity: .2
}

@media (min-width: 1280px) {

  .landing__rewards .carousel__prev--in-active,
  .landing__rewards .carousel__next--in-active {
    display: none
  }
}

.landing__rank .rank__item {
  position: relative;
  padding: 16px 0;
  display: flex;
  align-items: center;
  align-content: center;
  font-weight: 300;
  font-size: 1.5rem;
  line-height: 1.8125rem;
  flex-wrap: wrap
}

.landing__rank .rank__item:not(:last-child):after {
  content: "";
  display: block;
  height: 1px;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  background: linear-gradient(90deg, rgba(176, 206, 255, 0), rgba(137, 155, 187, .3), rgba(131, 155, 197, .3), rgba(180, 208, 255, 0))
}

.landing__rank .rank__item .v-img {
  width: 45px;
  flex-grow: 0
}

.landing__rank .rank__item .name {
  margin: 0 20px
}

.landing__rank .rank__item .duration {
  margin-left: auto
}

.landing__rank .rank__item .content {
  display: flex;
  align-items: center;
  align-content: center;
  width: calc(100% - 60px)
}

@media (max-width: 599.98px) {
  .landing__rank .rank__item .content {
    display: block;
    font-size: 1.25rem;
    line-height: 1.5;
    margin-left: 15px
  }

  .landing__rank .rank__item .name {
    margin: 0
  }
}

.landing__rank {
  padding: 40px 0
}

@media (min-width: 960px) {
  .landing__rank {
    padding: 100px 0
  }
}

.landing__rank p {
  font-size: 1.5rem;
  line-height: 1.8125rem;
  font-weight: 200
}

.landing__rank .rank__content {
  position: relative
}

.landing__rank .rank__list {
  margin: 64px 0 40px
}

.landing__rank .rank__list,
.landing__rank p {
  position: relative;
  z-index: 2;
  max-width: 600px
}

.landing__rank .rank__image {
  margin-top: -20%;
  margin-bottom: -10%
}

@media (min-width: 960px) {
  .landing__rank .rank__image {
    position: absolute;
    left: 50%;
    bottom: 0;
    max-width: 600px;
    width: 60%;
    margin: 0;
    transform: translate(20%, 10%)
  }
}

@media (max-width: 599.98px) {
  .landing__rank .rank__image {
    margin: 0 auto;
    max-width: 330px
  }
}

.coming-soon__item {
  background: linear-gradient(109.46deg, rgba(245, 245, 245, .12) 1.57%, rgba(255, 255, 255, .045) 100%);
  backdrop-filter: blur(30px);
  width: 100%;
  border-radius: 27px;
  padding: 24px;
  text-align: center;
  position: relative;
  align-self: stretch
}

.coming-soon__item:before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 27px;
  padding: 1px;
  background: linear-gradient(109.46deg, rgba(245, 245, 245, .12) 1.57%, rgba(255, 255, 255, .045) 100%), linear-gradient(108.87deg, rgba(255, 255, 255, .95) .66%, rgba(211, 211, 211, .1) 99.48%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude
}

@media (max-width: 1279.98px) {
  .coming-soon__item {
    max-width: 400px
  }
}

@media (min-width: 1920px) {
  .coming-soon__item {
    padding: 36px;
    max-width: 500px
  }
}

.coming-soon__item .title {
  font-weight: 600;
  font-size: 1.875rem;
  line-height: 2.375rem;
  text-transform: uppercase
}

@media (min-width: 960px) {
  .coming-soon__item .title {
    font-size: 2.5rem;
    line-height: 3rem
  }
}

@media (min-width: 1920px) {
  .coming-soon__item .title {
    font-size: 3rem;
    line-height: 3.5rem
  }
}

.coming-soon__item .subtitle {
  font-size: 1rem;
  line-height: 1.75rem;
  letter-spacing: .04em;
  color: #ffbb38;
  text-transform: uppercase
}

@media (min-width: 1920px) {
  .coming-soon__item .subtitle {
    font-size: 1.25rem;
    line-height: 2.125rem
  }
}

.coming-soon__item .v-img {
  max-width: 172px;
  margin: 16px auto;
  aspect-ratio: 1
}

.coming-soon__item p {
  font-size: 1rem;
  line-height: 1.5rem
}

@media (min-width: 1920px) {
  .coming-soon__item p {
    font-size: 1.25rem;
    line-height: 1.875rem
  }
}

.landing__coming-soon {
  padding: 40px 0
}

@media (min-width: 960px) {
  .landing__coming-soon {
    padding: 100px 0
  }
}

.landing__coming-soon .landing__text,
.landing__coming-soon .landing__title {
  position: relative;
  z-index: 2
}

.landing__coming-soon .coming-soon__list {
  margin-top: 40px
}

@media (min-width: 960px) {
  .landing__coming-soon .coming-soon__list {
    margin: 80px -60px 0
  }
}

.landing__coming-soon .carousel__slide {
  padding: 10px;
  display: flex
}

.landing__coming-soon .carousel__slide .coming-soon__item {
  align-self: stretch
}

@media (min-width: 960px) {
  .landing__coming-soon .carousel__pagination {
    display: none
  }
}

.landing__bubble {
  position: absolute;
  right: -20%;
  top: -10%;
  width: 400px;
  z-index: 1;
  animation: floating-item-small 5s infinite ease-in-out
}

@media (min-width: 600px) {
  .landing__bubble {
    right: -10%;
    top: -22%
  }
}

@media (min-width: 960px) {
  .landing__bubble {
    width: 602px;
    right: -17%;
    top: -20%
  }
}

@media (min-width: 2560px) {
  .landing__bubble {
    right: -30%;
    top: -15%
  }
}

.landing__bubble .bubble {
  position: relative;
  width: 100%
}

.landing__bubble--2 {
  animation-delay: .2s;
  animation-duration: 4.5s
}

.landing__bubble--3 {
  animation-delay: .7s;
  animation-duration: 4s
}

.landing__faq {
  padding: 40px 0 50px
}

@media (min-width: 960px) {
  .landing__faq {
    padding: 100px 0 150px
  }
}

.landing__faq .faq__list {
  margin-top: 44px;
  max-width: 600px
}

.landing__faq .v-expansion-panel {
  border-radius: 0;
  background: transparent;
  border-top: .79274px solid #cccccc
}

.landing__faq .v-expansion-panel:last-child {
  border-bottom: .79274px solid #cccccc
}

.landing__faq .v-expansion-panel__shadow,
.landing__faq .v-expansion-panel-title__overlay {
  display: none
}

.landing__faq .v-expansion-panel-text__wrapper {
  font-weight: 200
}

@media (max-width: 959.98px) {

  .landing__faq .v-expansion-panel-title,
  .landing__faq .v-expansion-panel-text__wrapper {
    padding-left: 1rem;
    padding-right: 1rem
  }
}

.landing__faq .v-expansion-panel-title,
.landing__faq .v-expansion-panel-text {
  font-size: 1rem;
  line-height: 1.375rem
}

@media (min-width: 960px) {

  .landing__faq .v-expansion-panel-title,
  .landing__faq .v-expansion-panel-text {
    font-size: 1.25rem;
    line-height: 1.75rem
  }
}

.landing__faq .v-expansion-panel-text p {
  margin-bottom: 1rem
}

.landing__faq .v-expansion-panel-text a {
  text-decoration: underline
}

.landing__head {
  position: absolute;
  right: 5%;
  top: 50%;
  width: 200px;
  z-index: 2;
  animation: floating-item 5s infinite ease-in-out
}

@media (max-width: 599.98px) {
  .landing__head {
    display: none
  }
}

@media (min-width: 1920px) {
  .landing__head {
    width: 260px
  }
}

.landing__head .head {
  position: relative;
  width: 100%
}

.landing__head--1 .head {
  transform: rotate(-10deg)
}

@media (min-width: 600px) and (max-width: 959.98px) {
  .landing__head--1 {
    display: none
  }
}

@media (min-width: 1280px) {
  .landing__head--1 {
    right: 20%
  }
}

.landing__head--2 {
  top: 10%;
  right: -10%;
  animation-delay: .2s;
  animation-duration: 4.5s
}

.landing__head--2 .head {
  transform: rotate(10deg)
}

@media (min-width: 1280px) {
  .landing__head--2 {
    right: 0%
  }
}

.v-expansion-panel {
  background-color: rgb(var(--v-theme-surface));
  color: rgba(var(--v-theme-on-surface), var(--v-high-emphasis-opacity))
}

.v-expansion-panel:not(:first-child):after {
  border-color: rgba(var(--v-border-color), var(--v-border-opacity))
}

.v-expansion-panel--disabled .v-expansion-panel-title {
  color: rgba(var(--v-theme-on-surface), .26)
}

.v-expansion-panel--disabled .v-expansion-panel-title .v-expansion-panel-title__overlay {
  opacity: .4615384615
}

.v-expansion-panels {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style-type: none;
  padding: 0;
  width: 100%;
  position: relative;
  z-index: 1
}

.v-expansion-panels:not(.v-expansion-panels--variant-accordion)>:not(:first-child):not(:last-child):not(.v-expansion-panel--active):not(.v-expansion-panel--before-active) {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important
}

.v-expansion-panels:not(.v-expansion-panels--variant-accordion)>:not(:first-child):not(:last-child):not(.v-expansion-panel--active):not(.v-expansion-panel--after-active) {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important
}

.v-expansion-panels:not(.v-expansion-panels--variant-accordion)>:first-child:not(.v-expansion-panel--active):not(.v-expansion-panel--before-active) {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important
}

.v-expansion-panels:not(.v-expansion-panels--variant-accordion)>:last-child:not(.v-expansion-panel--active):not(.v-expansion-panel--after-active) {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important
}

.v-expansion-panels--variant-accordion>:first-child {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important
}

.v-expansion-panels--variant-accordion>:last-child {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important
}

.v-expansion-panels--variant-accordion>:last-child .v-expansion-panel-title--active {
  border-bottom-left-radius: initial;
  border-bottom-right-radius: initial
}

.v-expansion-panels--variant-accordion>:not(:first-child):not(:last-child) {
  border-radius: 0 !important
}

.v-expansion-panels--variant-accordion .v-expansion-panel-title__overlay {
  transition: .3s border-radius cubic-bezier(.4, 0, .2, 1)
}

.v-expansion-panel {
  flex: 1 0 100%;
  max-width: 100%;
  position: relative;
  transition: .3s all cubic-bezier(.4, 0, .2, 1);
  transition-property: margin-top, border-radius, border, max-width;
  border-radius: 4px
}

.v-expansion-panel:not(:first-child):after {
  border-top-style: solid;
  border-top-width: thin;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: .3s opacity cubic-bezier(.4, 0, .2, 1)
}

.v-expansion-panel--disabled .v-expansion-panel-title {
  pointer-events: none
}

.v-expansion-panel--active:not(:first-child),
.v-expansion-panel--active+.v-expansion-panel {
  margin-top: 16px
}

.v-expansion-panel--active:not(:first-child):after,
.v-expansion-panel--active+.v-expansion-panel:after {
  opacity: 0
}

.v-expansion-panel--active>.v-expansion-panel-title {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  min-height: 64px
}

.v-expansion-panel__shadow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 3px 1px -2px #0003, 0 2px 2px #00000024, 0 1px 5px #0000001f;
  border-radius: inherit;
  z-index: -1
}

.v-expansion-panel-title {
  align-items: center;
  text-align: start;
  border-radius: inherit;
  display: flex;
  font-size: .9375rem;
  line-height: 1;
  min-height: 48px;
  outline: none;
  padding: 16px 24px;
  position: relative;
  transition: .3s min-height cubic-bezier(.4, 0, .2, 1);
  width: 100%;
  justify-content: space-between
}

.v-expansion-panel-title:hover>.v-expansion-panel-title__overlay {
  opacity: calc(.04 * var(--v-theme-overlay-multiplier))
}

.v-expansion-panel-title:focus-visible>.v-expansion-panel-title__overlay {
  opacity: calc(.12 * var(--v-theme-overlay-multiplier))
}

@supports not selector(:focus-visible) {
  .v-expansion-panel-title:focus>.v-expansion-panel-title__overlay {
    opacity: calc(.12 * var(--v-theme-overlay-multiplier))
  }
}

.v-expansion-panel-title--active>.v-expansion-panel-title__overlay,
.v-expansion-panel-title[aria-haspopup=menu][aria-expanded=true]>.v-expansion-panel-title__overlay {
  opacity: calc(.12 * var(--v-theme-overlay-multiplier))
}

.v-expansion-panel-title--active:hover>.v-expansion-panel-title__overlay,
.v-expansion-panel-title[aria-haspopup=menu][aria-expanded=true]:hover>.v-expansion-panel-title__overlay {
  opacity: calc(.12 * var(--v-theme-overlay-multiplier))
}

.v-expansion-panel-title--active:focus>.v-expansion-panel-title__overlay,
.v-expansion-panel-title[aria-haspopup=menu][aria-expanded=true]:focus>.v-expansion-panel-title__overlay {
  opacity: calc(.16 * var(--v-theme-overlay-multiplier))
}

.v-expansion-panel-title--active:before {
  opacity: .12
}

.v-expansion-panel-title__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: currentColor;
  border-radius: inherit;
  opacity: 0
}

.v-expansion-panel-title__icon {
  display: inline-flex;
  margin-bottom: -4px;
  margin-top: -4px;
  user-select: none;
  margin-inline-start: auto
}

.v-expansion-panel-text {
  display: flex
}

.v-expansion-panel-text__wrapper {
  padding: 8px 24px 16px;
  flex: 1 1 auto;
  max-width: 100%
}

.v-expansion-panels--variant-accordion>.v-expansion-panel {
  margin-top: 0
}

.v-expansion-panels--variant-accordion>.v-expansion-panel:after {
  opacity: 1
}

.v-expansion-panels--variant-popout>.v-expansion-panel {
  max-width: calc(100% - 32px)
}

.v-expansion-panels--variant-popout>.v-expansion-panel--active {
  max-width: calc(100% + 16px)
}

.v-expansion-panels--variant-inset>.v-expansion-panel {
  max-width: 100%
}

.v-expansion-panels--variant-inset>.v-expansion-panel--active {
  max-width: calc(100% - 32px)
}

.v-application.is-landing-page {
  background: linear-gradient(176.68deg, #272746 3.67%, #3f1e83 97.26%)
}

.v-application.is-landing-page .main {
  position: relative;
  overflow: hidden;
  height: 100% !important;
  padding-bottom: 100px;
}

.v-application.is-landing-page .main:before {
  content: "";
  display: block;
  position: absolute;
  top: 270vh;
  left: 0;
  transform: translate(-40%);
  background: url(/public/images/glow-2.bb3da2bd.png);
  background-size: contain;
  width: 180vh;
  height: 180vh;
}
.carousel__slide {
  padding: 20px;
  display: flex;
}

.v-application.is-landing-page .main:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(40%, 30%);
  background: url(/public/images/glow.4898b3a8.png);
  background-size: contain;
  width: 1300px;
  height: 1000px;
}

.v-application.is-landing-page section {
  position: relative
}

.landing__title {
  font-size: 1.75rem;
  line-height: 2.375rem;
  text-transform: uppercase;
  margin-bottom: .25rem
}

@media (min-width: 1280px) {
  .landing__title {
    font-size: 3rem;
    line-height: 3.625rem
  }
}

.landing__text {
  font-weight: 200;
  max-width: 600px;
  font-size: 1.25rem;
  line-height: 1.625rem
}

@media (min-width: 1280px) {
  .landing__text {
    font-size: 2rem;
    line-height: 2.4375rem
  }
}

.landing__ball {
  position: absolute;
  left: -10%;
  top: 90vh;
  width: 400px
}

.landing__ball--2 {
  width: 95vw;
  top: 154vh;
  left: auto;
  right: -40%
}

.landing__ball .ball {
  position: relative;
  width: 100%;
  animation: floating-item 5s infinite ease-in-out
}

@keyframes floating-item {
  0% {
    transform: translate(0)
  }

  65% {
    transform: translateY(40px)
  }

  to {
    transform: translate(0)
  }
}

@keyframes floating-item-small {
  0% {
    transform: translate(0)
  }

  65% {
    transform: translateY(20px)
  }

  to {
    transform: translate(0)
  }
}

.v-slide-group {
  display: flex;
}
.v-slide-group {
  position: relative;
}
.v-slide-group__container {
  contain: content;
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;
}

.v-slide-group__content {
  display: flex;
  flex: 1 0 auto;
  position: relative;
  transition: .2s all cubic-bezier(.4,0,.2,1);
  white-space: nowrap;
  transform: translateX(0px);
}

.announcement-banner {
  padding: 0 12px;
  overflow: hidden;
  width: 559px;
}

.v-slide-group__content>* {
  white-space: initial;
}

.announcement-banner .base-banner {
  align-items: baseline;
  min-height: 198px;
  height: 100%;
}

@media (min-width: 600px) {
.base-banner {
  height: 245px;
}
}

.base-banner {
  position: relative;
  height: 450px;
  width: 100%;
  overflow: hidden;
  align-items: center;
  align-content: center;
  font-size: .875rem;
  background-color: #282c38;
}

.base-banner__background .v-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.v-responsive {
  display: flex;
  flex: 1 0 auto;
  max-height: 100%;
  max-width: 100%;
  overflow: hidden;
  position: relative;
}
.v-img {
  --v-theme-overlay-multiplier: 3;
  z-index: 0;
}

.v-responsive__sizer {
  flex: 1 0 0px;
  transition: padding-bottom .2s cubic-bezier(.4,0,.2,1);
  pointer-events: none;
}

.v-img__img--cover {
  object-fit: cover;
}

.v-img__img, .v-img__picture, .v-img__gradient, .v-img__placeholder, .v-img__error {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.announcement-banner .base-banner .base-banner__content {
  padding: 0;
  margin: 1.5rem 2.5rem;
}
.base-banner__content {
  margin: 0 auto;
  max-width: none!important;
}
@media (min-width: 600px) {
.base-banner__content {
  width: 90%;
  max-width: 450px;
}
}
.base-banner__content {
  padding: 20px;
  position: relative;
  z-index: 2;
  color: #fff;
  white-space: pre-wrap;
  font-size: .75rem;
  line-height: 2;
}

.announcement-banner .announcement-ball {
  width: 230px;
  max-width: none;
  max-height: none;
  position: absolute;
  top: 15%;
  z-index: -1;
  left: -195px;
}

.announcement-banner .announcement-microphone {
  width: 100px;
  position: absolute;
  right: 0;
  top: 10%;
  z-index: -1;
}

@media (min-width: 960px) {
.announcement-banner .announcement-content {
    max-width: 70%;
}
}

.announcement-banner .announcement-content .type {
  font-size: .75rem;
  letter-spacing: .04em;
  text-transform: uppercase;
}

.announcement-banner .announcement-content .title {
  line-height: 1.797rem;
  font-weight: 700;
}

.section .title {
  font-size: 1.5rem;
  font-weight: 700;
}
.base-banner__content .title {
  font-family: filson-soft,sans-serif;
  font-size: 1.25rem;
  line-height: 1.2;
  margin-bottom: 1.75rem;
  font-weight: 700;
}
.my-2 {
  margin-top: 8px!important;
  margin-bottom: 8px!important;
}
.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
@media (min-width: 960px) {
.section {
    margin-top: 10px;
}
}
.section {
    padding: 30px 0;
}
.announcement-banner .announcement-content .description {
  font-size: .875rem;
  line-height: 1.375rem;
  font-weight: 500;
  letter-spacing: .04em;
  -webkit-line-clamp: 2;
  max-height: 40px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.base-banner__content p:not(:last-child) {
  margin-bottom: 0.625rem;
}
.section__header {
  margin-bottom: 20px;
}

.v-btn.v-btn--density-default {
  height: calc(var(--v-btn-height) + 0px);
}
.l000btn {
  background: #000;
  cursor: pointer;
}

.rotate-heu {
  filter: hue-rotate(45deg);
}




@media (min-width: 960px) {
.banner__title .t-aligned-c {
    margin-bottom: 2rem;
}
}

@media (min-width: 1280px) {
.banner__title .t-aligned-c {
    font-size: 4.5rem;
    line-height: 5.375rem;
}
}
.banner__title .t-aligned-c {
    font-size: 2rem;
    margin: 0 0 1rem;
    text-align: center;
    line-height: 1.1;
    font-weight: 700;
    padding-right: 24px;
    font-family: filson-soft, sans-serif;
    text-transform: uppercase;
}
.brand.w--current {
  max-width: 65px;
}

.logo-link .w-inline-block {
  background-color: transparent !important;
}

@-webkit-keyframes rotation1{
  from{-webkit-transform:rotate(0deg);-moz-transform:rotate(0deg);-ms-transform:rotate(0deg);-o-transform:rotate(0deg);transform:rotate(0deg)}
  to{-webkit-transform:rotate(360deg);-moz-transform:rotate(360deg);-ms-transform:rotate(360deg);-o-transform:rotate(360deg);transform:rotate(360deg)}}
  @-moz-keyframes rotation1{
  from{-webkit-transform:rotate(0deg);-moz-transform:rotate(0deg);-ms-transform:rotate(0deg);-o-transform:rotate(0deg);transform:rotate(0deg)}
  to{-webkit-transform:rotate(360deg);-moz-transform:rotate(360deg);-ms-transform:rotate(360deg);-o-transform:rotate(360deg);transform:rotate(360deg)}}
  @-o-keyframes rotation1{
  from{-webkit-transform:rotate(0deg);-moz-transform:rotate(0deg);-ms-transform:rotate(0deg);-o-transform:rotate(0deg);transform:rotate(0deg)}
  to{-webkit-transform:rotate(360deg);-moz-transform:rotate(360deg);-ms-transform:rotate(360deg);-o-transform:rotate(360deg);transform:rotate(360deg)}}
  
  .vector1{-moz-animation:rotation1 30s linear infinite;-o-animation:rotation1 30s linear infinite;-webkit-animation:rotation1 30s linear infinite;animation:rotation1 30s linear infinite}


  